import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue3 from 'bootstrap-vue-3';
import VueGoogleMaps from '@fawmi/vue-google-maps';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';

createApp(App)
	.use(router)
	.use(store)
	.use(BootstrapVue3)
	.use(VueGoogleMaps, {
		load: {
			key: 'AIzaSyDttJadFf5cWEcGQMFmRZCrjzUFony74xI',
		},
		})
	.directive('click-outside', {
		beforeMount(el, binding) {
			el.clickOutsideEvent = function (event) {
				if (!(el.contains(event.target))) {
					binding.value(event);
				}
			};
			document.addEventListener('click', el.clickOutsideEvent)
		},
		unmounted(el) {
			document.removeEventListener('click', el.clickOutsideEvent)
		},
	})  
	.mount('#app')
