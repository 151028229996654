<template>
    <div class="nav-bar-operations">
        <img src="@/assets/logogruas.png" alt="HomePage" @click="navegar" class="logo-button"/>
    </div>
    <div class="create-order">
        <h2 class="order-type">Tipo de orden: Lote</h2>
        <button @click="showForm('initialData')">Datos iniciales</button>
        <button @click="showForm('payment')">Cobro</button>
    </div>
    <div v-if="currentForm === 'initialData'">
        <div class="form-container">
            <div class="form-section">
                <h3>Datos del cliente</h3>
                <img src="@/assets/client-icon.png">
                <!-- Client Form -->
                <div class="form-group">
                    <label for="client_name">Cliente</label>
                    <input type="text" id="client_name" placeholder="Escribe y seleccione el nombre del cliente" v-model="client_name" @input="filterClients" autocomplete="off" />
                    <div class="client-dropdown" v-if="filteredClients.length" v-click-outside="closeDropdown">
                        <div v-for="client in filteredClients" :key="client.id" @click="selectClient(client)">
                            {{ client.client_name }}
                        </div>
                    </div>
                </div>
                <div class="button-add-new-client" v-if="!isClientSelected">
                    <button @click="toggleNewClientForm" class="btn-submit-save" v-if="!addNewClient">Agregar nuevo cliente</button>
                </div>
                <div v-if="isClientSelected">
                    <div class="form-group">
                        <label for="client_street">Calle</label>
                        <label>{{ clientDetails.client_address.street }}</label>
                    </div>
                    <div class="form-group">
                        <label for="client_codigoPostal">C.P. (Código Postal)</label>
                        <label>{{ clientDetails.client_address.codigoPostal }}</label>
                    </div>
                    <div class="form-group">
                        <label for="client_neighborhood">Colonia</label>
                        <label>{{ clientDetails.client_address.neighborhood }}</label>
                    </div>
                    <div class="form-group">
                        <label for="client_city">Ciudad</label>
                        <label>{{ clientDetails.client_address.city }}</label>
                    </div>
                    <div class="form-group">
                        <label for="client_state">Estado</label>
                        <label>{{ clientDetails.client_address.state }}</label>
                    </div>
                    <div class="form-group">
                        <label for="client_rfc">RFC</label>
                        <label>{{ clientDetails.client_rfc }}</label>
                    </div>
                    <div class="form-group">
                        <label for="phone">Teléfono</label>
                        <label>{{ clientDetails.phone }}</label>
                    </div>
                </div>
                <div v-if="addNewClient">
                    <div class="container">
                        <label class="switch">
                            <input type="checkbox" id="isVip" v-model="is_privileged">
                            <span class="slider round"></span>
                        </label>
                        <label for="isVip"> Recurrente </label>
                        <!--<input type="checkbox" id="isVip" v-model="is_privileged" />-->   
                    </div>
                    <div class="form-group">
                        <label for="client_street">Calle</label>
                        <input type="text" id="client_street" placeholder="Ingrese una ciudad" v-model="clientDetails.client_address.street" />
                    </div>
                    <div class="form-group">
                        <label for="client_codigoPostal">C.P. (Código Postal)</label>
                        <input type="text" id="client_codigoPostal" placeholder="Ej. 64849" v-model="clientDetails.client_codigoPostal" @keypress="validatePositiveIntegerField"/>
                    </div>
                    <div class="form-group">
                        <label for="client_neighborhood">Colonia</label>
                        <input type="text" id="client_neighborhood" placeholder="Ej. Tecnológico" v-model="client_neighborhood" />
                    </div>
                    <div class="form-group">
                        <label for="client_city">Ciudad</label>
                        <input type="text" id="client_city" placeholder="Ingrese una ciudad" v-model="client_city" />
                    </div>
                    <div class="form-group">
                        <label for="client_state">Estado</label>
                        <select id="client_state" v-model="client_state">
                            <option v-for="state in allStates" :key="state.name" :value="state.name">
                                {{ state.name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="client_rfc">RFC</label>
                        <input type="text" id="client_rfc" placeholder="Ingrese el RFC" v-model="clientDetails.client_rfc" />
                    </div>
                    <div class="form-group">
                        <label for="phone">Teléfono</label>
                        <input type="text" id="phone" placeholder="Ingrese un número de celular" v-model="clientDetails.phone" />
                    </div>
                    <button type="submit" class="btn-submit-save" @click="saveNewClient">Guardar datos del nuevo cliente</button>
                    <button @click="cancelNewClient" class="btn-submit-cancel">Cancelar</button>
                </div>
            </div>

            <div class="form-section">
                <h3>Datos del vehículo</h3>
                <img src="@/assets/vehicle-icon.png">
                <!-- Vehicle Form -->
                <div class="form-group">
                    <label for="vehicle_brand">Marca</label>
                    <select v-model="SelectedBrand" @change="FetchFilteredSubBrands">
                        <option v-for="(brand,pk) in brands" :key="brand" :value="brand">
                            {{ pk }}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="vehicle_subbrand">Submarca</label>
                    <select v-model="SelectedSubBrand" :disabled="subBrandLoading" :class="{'sub-brand-loading-grayed-out': subBrandLoading}">
                        <option v-for="(sub_brand,id) in sub_brands" :key="sub_brand" :value="sub_brand">
                            {{ id }}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="vehicle_model">Modelo</label>
                    <input type="text" id="vehicle_model" placeholder="Si desconoce, deje vacío. (Formato YYYY)" v-model="vehicle_model" @keypress="validatePositiveIntegerField"/>
                </div>
                <div class="form-group">
                    <label for="vehicle_plates">Placas</label>
                    <input type="text" id="vehicle_plates" placeholder="Ingrese las placas" v-model="vehicle_plates" />
                </div>
                <div class="form-group">
                    <label for="vehicle_engine_series">Serie del motor</label>
                    <input type="text" id="vehicle_engine_series" placeholder="Ingrese la serie del motor" v-model="vehicle_engine_series" />
                </div>
                <div class="form-group">
                    <label for="vehicle_color">Color</label>
                    <input type="text" id="vehicle_color" placeholder="Ingrese el color" v-model="vehicle_color" />
                </div>
                <div class="form-group">
                    <label for="insurance_policy">Póliza</label>
                    <input type="text" id="insurance_policy" placeholder="Ingrese la póliza" v-model="insurance_policy" />
                </div>
                <div class="form-group">
                    <label for="vehicle_owner_name">Dueño (Asegurado)</label>
                    <input type="tel" id="vehicle_owner_name" placeholder="Ingrese el nombre del asegurado" v-model="vehicle_owner_name" />
                </div>
                <div class="form-group">
                    <label for="file_number">Expediente</label>
                    <input type="tel" id="file_number" placeholder="Ingrese el expediente" v-model="file_number" />
                </div>
                <div class="form-group">
                <label for="sucursal">Sucursal</label>
                <select id="sucursal" v-model="sucursal" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option value="ASISTENCIA AUXILIO VIAL">ASISTENCIA AUXILIO VIAL</option>
                        <option value="ASISTENCIA AUXILIO VIAL FORANEAS">ASISTENCIA AUXILIO VIAL FORANEA</option>
                        <option value="ASISTENCIAS FORANEAS">ASISTENCIAS FORANEAS</option>
                        <option value="ASISTENCIAS">ASISTENCIAS</option>
                        <option value="ASISTENCIAS PARTICULAR">ASISTENCIAS PARTICULAR</option>
                        <option value="AUTOPISTA LAREDO">AUTOPISTA LAREDO</option>
                        <option value="CANCELADOS">CANCELADOS</option>
                        <option value="CONTADO">CONTADO</option>
                        <option value="DETENIDOS">DETENIDOS</option>
                        <option value="ELECTRONICA INDUSTRIA">ELECTRONICA INDUSTRIA</option>
                        <option value="ELECTRONICA REMOLQUE">ELECTRONICA REMOLQUE</option>
                        <option value="FILIALES">FILIALES</option>
                        <option value="FORANEOS">FORANEOS</option>
                        <option value="REVOLUCION">REVOLUCION</option>
                        <option value="HYLSA">HYLSA</option>
                        <option value="INSTALACION">INSTALACION</option>
                        <option value="LOTE RIO ESCONDIDO"> LOTE RIO ESCONDIDO</option>
                        <option value="LOTE SALTILLO">LOTE SALTILLO</option>
                        <option value="LOTE SANTA CATARINA Q-0420"> LOTE SANTA CATARINA Q-0420</option>
                        <option value="LOTE SANTA ROSA"> LOTE SANTA ROSA</option>
                        <option value="LOTE CHINA">LOTE CHINA</option>
                        <option value="LOTE GUADALUPE">LOTE GUADALUPE</option>
                        <option value="LOTE GUADALUPE FEDERAL">LOTE GUADALUPE FEDERAL</option>
                        <option value="LOTE JARDINES">LOTE JARDINES</option>
                        <option value="LOTE REVOLUCION">LOTE REVOLUCION</option>
                        <option value="LOTE SANTA CATARINA">LOTE SANTA CATARINA</option>
                        <option value="MANTENIMIENTO">MANTENIMIENTO</option>
                        <option value="METRO GRUAS">METRO GRUAS</option>
                        <option value="NOGALAR">NOGALAR</option>
                        <option value="OVERHAULING">OVERHAULING</option>
                        <option value="REFACTURACION">REFACTURACION</option>
                        <option value="REMOLQUE">REMOLQUE</option>
                        <option value="SAN RAFAEL">SAN RAFAEL</option>
                        <option value="SUC SANTA CATARINA">SUC SANTA CATARINA</option>
                        <option value="TRANSITO SANTA CATARINA">TRANSITO SANTA CATARINA</option>
                        <option value="VENTAS">VENTAS</option>
                        <option value="VENTA DE ACCESORIOS">VENTA DE ACCESORIOS</option>
                        <option value="VENTA DE EQUIPO USADO">VENTA DE EQUIPO USADO</option>
                        <option value="Z ALFONSO REYES">Z ALFONSO REYES</option>
                </select>
                </div>
                <div class="form-group">
                    <label for="expected_time">Tiempo estimado:</label>
                    <input type="tel" id="expected_time" placeholder="Ingrese el tiempo esperado en minutos" v-model="expected_time" />
                </div>
                <div class="form-group">
                    <label for="????">Lote</label>
                    <select type="tel" id="payment_method" v-model="lot">
                        <option value="LOTE SANTA CATARINA">LOTE SANTA CATARINA</option>
                        <option value="LOTE SAN ROBERTO">LOTE SAN ROBERTO</option>
                        <option value="LOTE SAN RAFAEL">LOTE SAN RAFAEL</option>
                        <option value="LOTE SAN CHINA">LOTE CHINA</option>
                        <option value="RESGUARDO">RESGUARDO</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="????">Motivo de retención</label>
                    <select type="tel" id="payment_method" v-model="lot_retention">
                        <option value="A DISPOSICION DE SU DUEÑO">A DISPOSICION DE SU DUEÑO</option>
                        <option value="ABANDONO">ABANDONO</option>
                        <option value="ACCIDENTE">ACCIDENTE</option>
                        <option value="ATROPELLO">ATROPELLO</option>
                        <option value="EMBARGO">EMBARGO</option>
                        <option value="INFRACCIONES">INFRACCIONES</option>
                        <option value="INVESTIGACIONES">INVESTIGACIONES</option>
                        <option value="OPERATIVO AA">OPERATIVO AA</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="????">Autoridad</label>
                    <input type="tel" id="????" placeholder="Ingrese la autoridad" v-model="lot_authority"/>
                </div>

                <div class="form-group">
                    <label>Fecha y Hora de Ingreso a Lote</label>
                        <div class="text-color-for-date"></div>
                        <input type="datetime-local" v-model="lot_start_time" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
                <div class="form-group">
                    <label>Fecha y Hora de salida del lote</label>
                        <div class="text-color-for-date"></div>
                        <input type="datetime-local" v-model="lot_end_time" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }"/>
                </div>
            </div>

            <div class="form-section">
                <h3>Ubicación de Origen</h3>
                <img src="@/assets/ubi-icon.png">
                <!-- Source Form -->
                <div class="checkbox-group">
                    <input type="checkbox" id="toggleFields" v-model="showOptionalFields">
                    <label for="toggleFields">Servicio carretero</label>
                </div>
                <div class="form-group" >
                    <label for="source_street">Calle</label>
                    <input type="text" id="source_street" v-model = "source_street" placeholder="Ingrese la calle principal" />
                </div>
                <div class="form-group" v-show="!showOptionalFields">
                    <label for="source_number">Número</label>
                    <input type="text" id="source_number" v-model = "source_street_number" placeholder="Ingrese el número" />
                </div>
                <div class="form-group" v-show="!showOptionalFields">
                    <label for="source_inbtw">Entrecalles</label>
                    <input type="text" id="source_inbtw" v-model = "source_between_streets" placeholder="Ingrese entre que calles se encuentra" />
                </div>
                <div class="form-group" v-show="!showOptionalFields">
                    <label for="source_neighborhood">Colonia</label>
                    <input type="text" id="source_neighborhood" v-model = "source_neighborhood" placeholder="Ingrese la colonia" />
                </div>
                <div class="form-group" >
                    <label for="source_postal_code">C.P (Código Postal)</label>
                    <input type="text" id="source_postal_code" v-model = "source_zip_code" placeholder="Ingrese el código postal" @keypress="validatePositiveIntegerField"/>
                </div>
                <div class="form-group" v-show="!showOptionalFields">
                    <label for="source_municipality">Municipio</label>
                    <input type="text" id="source_municipality" v-model = "source_city"  placeholder="Ingrese el municipio" />
                </div>
                <div class="form-group" v-show="!showOptionalFields">
                    <label for="source_state">Estado</label>
                    <select id="source_state" v-model="source_state">
                        <option v-for="state in allStates" :key="state.name" :value="state.name">
                            {{ state.name }}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="source_lat">Latitud</label>
                    <input type="tel" id="source_lat" v-model="source_lat" placeholder="Ingrese la latitud" @keypress="validateFloatField"/>
                </div>
                <div class="form-group">
                    <label for="source_long">Longitud</label>
                    <input type="tel" id="source_long" v-model="source_lon" placeholder="Ingrese la longitud" @keypress="validateFloatField"/>
                </div>
                <div class="form-group" v-show="showOptionalFields">
                    <label for="source_km">KM</label>
                    <input type="tel" id="source_km" v-model = "source_km" placeholder="Ingrese el KM de la carretera" @keypress="validatePositiveIntegerField"/>
                </div>
                <div class="form-group">
                    <label for="source_notes">Referencias</label>
                    <textarea id="source_notes" rows="5" v-model = "source_references" placeholder="Ingrese información adicional"></textarea>
                </div>  
            </div>

            <div class="form-section">
                <h3>Ubicación de Destino</h3>
                <img src="@/assets/ubi-des-icon.png">
                <!-- Destination Form -->
                <div class="checkbox-group">
                    <input type="checkbox" id="toggleFields1" v-model="showOptionalFields1">
                    <label for="toggleFields1">Servicio carretero</label>
                </div>
                <div class="form-group" >
                    <label for="source_street">Calle</label>
                    <input type="text" id="source_street" v-model= "destination_street" placeholder="Ingrese la calle principal" />
                </div>
                <div class="form-group" v-show="!showOptionalFields1">
                    <label for="source_number">Número</label>
                    <input type="text" id="source_number" v-model = "destination_street_number" placeholder="Ingrese el número" />
                </div>
                <div class="form-group" v-show="!showOptionalFields1">
                    <label for="source_inbtw">Entrecalles</label>
                    <input type="text" id="source_inbtw" v-model= "destination_between_streets" placeholder="Ingrese entre que calles se encuentra" />
                </div>
                <div class="form-group" v-show="!showOptionalFields1">
                    <label for="source_neighborhood">Colonia</label>
                    <input type="text" id="source_neighborhood" v-model = "destination_neighborhood" placeholder="Ingrese la colonia" />
                </div>
                <div class="form-group" v-show="!showOptionalFields1">
                    <label for="source_postal_code">C.P (Código Postal)</label>
                    <input type="text" id="source_postal_code" v-model="destination_zip_code" placeholder="Ingrese el código postal" @keypress="validatePositiveIntegerField"/>
                </div>
                <div class="form-group" v-show="!showOptionalFields1">
                    <label for="source_municipality">Municipio</label>
                    <input type="text" id="source_municipality" v-model="destination_city" placeholder="Ingrese el municipio" />
                </div>
                <div class="form-group" v-show="!showOptionalFields1">
                    <label for="destination_state">Estado</label>
                    <select id="destination_state" v-model="destination_state">
                        <option v-for="state in allStates" :key="state.name" :value="state.name">
                            {{ state.name }}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="source_lat">Latitud</label>
                    <input type="tel" id="destination_lat" v-model="destination_lat" placeholder="Ingrese la latitud" @keypress="validateFloatField"/>
                </div>
                <div class="form-group">
                    <label for="source_long">Longitud</label>
                    <input type="tel" id="destination_long" v-model="destination_lon" placeholder="Ingrese la longitud" @keypress="validateFloatField"/>
                </div>
                <div class="form-group" v-show="showOptionalFields1">
                    <label for="source_km">KM</label>
                    <input type="tel" id="source_km" v-model="destination_km" placeholder="Ingrese el KM de la carretera" @keypress="validatePositiveIntegerField"/>
                </div>
                <div class="form-group">
                    <label for="source_notes">Referencias</label>
                    <textarea id="source_notes" rows="5" v-model="destination_references" placeholder="Ingrese información adicional"></textarea>
                </div>
            </div>
        </div>
    </div>

    <div v-if="currentForm === 'payment'">
        <div class="form-section">
            <h3>Costos</h3>
            <img src="@/assets/cost-icon.png">
        </div>
        <div class="form-container">
            <div class="form-section">
                <div class="form-group">
                    <label for="cost_by_km">Costo por kilómetro</label>
                    <input type="text" id="cost_by_km" placeholder="Ingrese el costo por km" v-model="cost_by_km" @keypress="validateFloatField"/>
                </div>
                <div class="form-group">
                    <label for="total_kms">Total de kilómetros</label>
                    <input type="text" id="total_kms" placeholder="Ingrese el total de kms" v-model="total_kms" @keypress="validateFloatField"/>
                </div>
                <div class="form-group">
                    <label for="total_kms">Costo por tráfico</label>
                    <input type="text" id="cost_by_traffic" placeholder="Ingrese el costo por tráfico" v-model="cost_by_traffic" @keypress="validateFloatField"/>
                </div>
                <div class="form-group">
                    <label for="maneuvers">Maniobras</label>
                    <input type="text" id="maneuvers" placeholder="Ingrese las maniobras realizadas" v-model="maneuvers" @keypress="validateFloatField"/>
                </div>
                <div class="form-group">
                    <label for="highway_tolls">Casetas</label>
                    <input type="text" id="highway_tolls" placeholder="Ingrese el número de casetas pagadas" v-model="highway_tolls" @keypress="validateFloatField"/>
                </div>
                <div class="form-group">
                    <label for="subtotal">Subtotal</label>
                    <input type="text" id="subtotal" placeholder="Ingrese el subtotal" v-model="subtotal" @keypress="validateFloatField"/>
                </div>
            </div>
            <div class="form-section">
                <div class="form-group">
                        <label for="iva">IVA</label>
                        <input type="text" id="iva" placeholder="Ingrese el iva" v-model="iva" @keypress="validateFloatField"/>
                </div>
                <div class="form-group">
                    <label for="retention">Retención</label>
                    <input type="tel" id="retention" placeholder="Ingrese la retención" v-model="retention" @keypress="validateFloatField"/>
                </div>
                <div class="form-group">
                    <label for="total">Total</label>
                    <input type="tel" id="total" placeholder="Ingrese el total" v-model="total" @keypress="validateFloatField"/>
                </div>
                <div class="form-group">
                    <label for="total_ovr">Nuevo final Total</label>
                    <input type="tel" id="total_ovr" placeholder="Ingrese el nuevo final total" v-model="total_ovr" @keypress="validateFloatField"/>
                </div>
            </div>
            <div class="form-section">
                <div class="form-group">
                    <label for="payment_method">Método de pago</label>
                    <select type="tel" id="payment_method" v-model="payment_method" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option value="PPD">PAGO EN PARCIALIDADES O DIFERIDO</option>
                        <option value="PUE">PAGO EN UNA EXHIBICIÓN</option>
                    </select>
                    </div>
                <div class="form-group">
                    <label for="payment_type">Tipo de pago</label>
                    <select id="payment-type" v-model="payment_type" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option value="99" v-if="payment_method === 'PPD'">POR DEFINIR</option>
                        <template v-if="payment_method !== 'PPD'">
                            <option value="99">POR DEFINIR</option>
                            <option value="04">TARJETA DE CRÉDITO</option>
                            <option value="03">TRANSFERENCIA</option>
                            <option value="01">EFECTIVO</option>
                            <option value="28">TARJETA DE DÉBITO</option>
                            <option value="02">CHEQUE NOMINATIVO</option>
                        </template>
                    </select>
                </div>
                <div class="form-group">
                    <label for="invoice_use">CFDI</label>
                    <select id="invoice_use" v-model="invoice_use" :disabled="blockEdit" :class="{ 'grayed-out': blockEdit }">
                        <option value="G01">ADQUISICIÓN DE MERCANCÍAS</option>
                        <option value="G02">DEVOLUCIOINES, DESCUENTOS O BONIFICACIONES</option>
                        <option value="G03">GASTOS EN GENERAL</option>
                        <option value="I01">CONSTRUCCIONES</option>
                        <option value="I02">MOBILIARIO Y EQUIPO DE OFICINA POR INVERSIONES</option>
                        <option value="I03">EQUIPO DE TRANSPORTE</option>
                        <option value="I04">EQUIPO DE CÓMPUTO Y ACCESORIOS</option>
                        <option value="I05">DADOS, TROQUELES, MOLDES, MATRICES Y HERRAMENTAL</option>
                        <option value="I06">COMUNICACIONES TELEFÓNICAS</option>
                        <option value="I07">COMUNICACIONES SATELITALES</option>
                        <option value="I08">OTRA MAQUINARIA Y EQUIPO</option>
                        <option value="D01">HONORARIOS MÉDICOS, DENTALES Y GASTOS HOSPITALARIOS</option>
                        <option value="D02">GASTOS MÉDICOS POR INCAPACIDAD O DISCAPACIDAD</option>
                        <option value="D03">GASTOS FUNERALES</option>
                        <option value="D04">DONATIVOS</option>
                        <option value="D05">INTERESES REALES EFECTIVAMENTE PAGADOS POR CRÉDITOS HIPOTECARIOS</option>
                        <option value="D06">APORTACIONES VOLUNTARIAS AL SAR</option>
                        <option value="D07">PRIMAS POR SEGUROS DE GASTOS MÉDICOS</option>
                        <option value="D08">GASTOS DE TRANSPORTACIÓN ESCOLAR OBLIGATORIA</option>
                        <option value="D09">DEPÓSITOS EN CUENTAS PARA EL AHORRO </option>
                        <option value="D10">PAGOS POR SERVICIOS EDUCATIVOS</option>
                        <option value="S01">SIN EFECTOS FISCALES</option>
                        <option value="CP01">PAGOS</option>
                        <option value="CN01">NÓMINA</option>
                </select>
                </div>
                <div class="form-group">
                    <label for="payment-notes">Notas del pago</label>
                    <textarea id="payment-notes" rows="5" placeholder="Ingrese notas del pago" v-model="payment_notes" ></textarea>
                </div>
            </div>
        </div>
    </div>

    <button type="submit" class="btn-submit-save" @click="saveNote" :disabled="!isFormValid||saving_note" :class="{ 'grayed-out': !isFormValid,'saving-note': saving_note}">Guardar</button>
    <button type="button" class="btn-submit-cancel" onclick="history.back()">Cancelar</button>

</template>

<style scoped>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  margin-left: -10px; 
  margin-right: 15px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}
.container {
  display: flex;
  align-items: center; /* Align items vertically */
  text-align: left; 
  font-weight: bold; 
}
.nav-bar-operations {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100vw; 
    height: 10vh; 
    background-color: #FFC434;
    z-index: 1000; 
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
}
.create-order{
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    height: 10vh;
    border-bottom: 1px solid black;
    margin-left: 20px;
    margin-right: 20px;
}
.create-order button {
    padding: 5px;
    background-color: rgb(0, 17, 255);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 3vh;
}
.order-type{
    display: flex;
    margin-top: 5vh;
}
.form-group {
    margin-bottom: 15px;
    width: 10vw;
}
label {
    display: block;
    margin-bottom: 10px;
}
input[type="text"],
input[type="tel"] {
    width: 20vw;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.btn-submit-save {
    background-color: #00ff1e; 
    color: #fff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}
.btn-submit-cancel {
    background-color: #ff0000; 
    color: #fff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}
.form-container {
    display: flex;
    justify-content: space-between;
    margin: 5px;
}
.form-section {
    flex-basis: calc(33% - 10px); 
    margin-right: 10px;
    background: #fff; 
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1); 
}
.form-section:last-child {
    margin-right: 0;
}

h2 {
    margin-bottom: 20px;
}
.form-group label {
    text-align: left; 
    font-weight: bold; 
    display: inline-block; 
    width: 100%; 
}
.form-section img{
    width: 40px;
    height: 40px;
}
.form-check-input {
    width: 20px;
}
textarea {
    width: 20vw; 
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    resize: vertical; 
}
.checkbox-group {
    display: flex;
    justify-content: flex-start; 
    gap: 5px; 
    font-weight: bold;   
}
.button-delete-operator{
    background-color: #ff0000;
    color: white;
    margin-left: 3vw;
}
.form-group .client-dropdown {
    position: absolute;
    z-index: 1000;
    width: 20vw; 
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    max-height: 200px;
    overflow-y: auto; 
}
.client-dropdown div {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
}
.client-dropdown div:hover {
    background-color: #f2f2f2;
}
.form-group select {
    width: 20vw; 
    padding: 8px; 
    margin-bottom: 10px; 
    border: 1px solid #ccc;
    border-radius: 4px; 
    background-color: white; 
    -webkit-appearance: none; 
    -moz-appearance: none; 
    appearance: none; 
    position: relative;
    background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>'); 
    background-repeat: no-repeat;
    background-position: right 8px top 50%; 
    background-size: 12px; 
}
.grayed-out {
    opacity: 0.5; /* You can adjust the opacity to your preference */
    cursor: not-allowed;
    position: relative; /* Ensure button's position is relative */
}
.grayed-out::after {
    content: "Los campos: Cliente, Marca, Submarca, Latitud y Longitud de origen, Latitud y Longitud de destino son obligatorios"; /* Text to show on mouse hover */
    position: absolute;
    top: -50%; /* Center the text vertically */
    left: 50%; /* Center the text horizontally */
    transform: translate(-50%, -50%); /* Center the text both vertically and horizontally */
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    white-space: nowrap;
    z-index: 999;
    opacity: 0; /* Initially hide the text */
    transition: opacity 0.3s ease;
}
.grayed-out:hover::after {
    opacity: 1; /* Show the text on hover */
}
.sub-brand-loading-grayed-out {
    opacity: 0.5; /* You can adjust the opacity to your preference */
    cursor: not-allowed;
    position: relative; /* Ensure button's position is relative */
}
.saving-note {
    opacity: 0.5; /* You can adjust the opacity to your preference */
    cursor: not-allowed;
    position: relative; /* Ensure button's position is relative */
}
.saving-note::after {
    content: "Espere a que se guarde la nota"; /* Text to show on mouse hover */
    position: absolute;
    top: -50%; /* Center the text vertically */
    left: 50%; /* Center the text horizontally */
    transform: translate(-50%, -50%); /* Center the text both vertically and horizontally */
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    white-space: nowrap;
    z-index: 999;
    opacity: 0; /* Initially hide the text */
    transition: opacity 0.3s ease;
}

.saving-note:hover::after {
    opacity: 1; /* Show the text on hover */
}
@media screen and (min-height: 930px) {
    .create-order{
        display: flex;
        justify-content: space-between; 
        align-items: center; 
        height: 10vh;
        border-bottom: 1px solid black;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 6vh;
    }
}
</style>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            client_state: '',
            allStates: [
                { name: 'Aguascalientes' },
                { name: 'Baja California' },
                { name: 'Baja California Sur' },
                { name: 'Campeche' },
                { name: 'Chiapas' },
                { name: 'Chihuahua' },
                { name: 'Ciudad de México' },
                { name: 'Coahuila' },
                { name: 'Colima' },
                { name: 'Durango' },
                { name: 'Estado de México' },
                { name: 'Guanajuato' },
                { name: 'Guerrero' },
                { name: 'Hidalgo' },
                { name: 'Jalisco' },
                { name: 'Michoacán' },
                { name: 'Morelos' },
                { name: 'Nayarit' },
                { name: 'Nuevo León' },
                { name: 'Oaxaca' },
                { name: 'Puebla' },
                { name: 'Querétaro' },
                { name: 'Quintana Roo' },
                { name: 'San Luis Potosí' },
                { name: 'Sinaloa' },
                { name: 'Sonora' },
                { name: 'Tabasco' },
                { name: 'Tamaulipas' },
                { name: 'Tlaxcala' },
                { name: 'Veracruz' },
                { name: 'Yucatán' },
                { name: 'Zacatecas' }
            ],
            employeeid:'',
            filteredClientStates: [],
            filteredSourceStates: [],
            filteredDestinationStates: [],
            client_name: '',
            client_address: '',
            client_rfc: '',
            phone:'',
            is_privileged: false,
            brands: [],
            sub_brands: [],
            subBrandLoading: true,
            SelectedBrand: null,
            SelectedSubBrand: null,
            vehicle_model: '',
            vehicle_plates: '',
            vehicle_engine_series: '',
            vehicle_color: '',
            insurance_policy: '',
            vehicle_owner_name: '',
            file_number: '',
            source_street: '',
            source_between_streets: '',
            source_neighborhood: '',
            source_street_number: '',
            source_city: '',
            source_zip_code: '',
            source_state: '',
            source_lat: '',
            source_lon: '',
            source_km: 0,
            source_references: '',
            destination_street: '',
            destination_between_streets: '',
            destination_neighborhood: '',
            destination_street_number: '',
            destination_city: '',
            destination_zip_code: '',
            destination_state: '',
            destination_lat: '',
            destination_lon: '',
            destination_km: 0,
            destination_references: '',
            cost_by_km: 0,
            total_kms: 0,
            maneuvers: 0,
            highway_tolls: 0,
            subtotal: 0,
            iva: 0,
            retention: 0,
            total : 0,
            total_ovr : 0,
            payment_method : '',
            payment_type : '',
            cfdi_use : '',
            payment_notes : '',
            sucursal: '',
            showOptionalFields: false,
            showOptionalFields1: false,
            addNewClient: false,
            clients: [], 
            filteredClients: [], 
            clientDetails: {
                "client_address": {
                    
                }
            }, 
            isClientSelected: false,
            currentForm: 'initialData',
            saving_note: false,
            lot: '',
            lot_retention: '',
            lot_authority: '',
            lot_start_time: null,
            lot_end_time: null,
            lot_cost_per_day: 0,
            lot_total_cost: 0,
            expected_time: '',
            cost_by_traffic: 0
        };
    },
    watch: {
        client_name(newValue) {
            if (newValue) {
                this.isClientSelected = this.clients.some(client => client.client_name === newValue);
            } else {
                this.isClientSelected = false;
            }
        },
        client_state(newValue) {
            this.filteredClientStates = this.getFilteredStates(newValue);
        },
        source_state(newValue) {
            this.filteredSourceStates = this.getFilteredStates(newValue);
        },
        destination_state(newValue) {
            this.filteredDestinationStates = this.getFilteredStates(newValue);
        },
    },
    computed: {
        isFormValid() {
            /** Used to prevent the form submission until all required values are set */
            return (
                // In case more needed in the future, uncommend, as of writting only brand
                // sub brand, client, and lat lon are required 
                this.client_id !== null &&
                this.SelectedBrand !== null && this.SelectedBrand !== "" &&
                this.SelectedSubBrand !== null && this.SelectedSubBrand !== "" &&
                //this.vehicle_model !== "" &&
                //this.vehicle_plates !== "" &&
                //this.vehicle_engine_series !== "" &&
                //this.vehicle_color !== "" &&
                //this.insurance_policy !== "" &&
                //this.vehicle_owner_name !== "" &&
                //this.file_number !== "" &&
                //this.source_street !== "" &&
                //this.source_street_number !== "" &&
                //this.source_between_streets !== "" &&
                //this.source_neighborhood !== "" &&
                //this.source_city !== "" &&
                //this.source_zip_code !== "" &&
                //this.source_state !== "" &&
                this.source_lat !== "" &&
                this.source_lon !== "" &&
                //this.destination_street !== "" &&
                //this.destination_street_number !== "" &&
                //this.destination_between_streets !== "" &&
                //this.destination_neighborhood !== "" &&
                //this.destination_city !== "" &&
                //this.destination_zip_code !== "" &&
                //this.destination_state !== "" &&
                this.destination_lat !== "" &&
                this.destination_lon !== "" //&&
                //this.source_km !== "" &&
                //this.source_references !== "" &&
                //this.destination_km !== "" &&
                //this.destination_references !== "" &&
                //this.employeeid !== ""
            );
        }
    },
    created() {
        this.fetchCurrentUser();
    },
    methods: {
        fetchCurrentUser() {
            const apiDirectory = process.env.API_DIRECTORY;
            axios.get(`${apiDirectory}/current-user/`, {
                headers: {
        'Authorization': 'Token ' + localStorage.getItem('authToken')
        }
            })
            .then(response => {
                this.employeeid = response.data.employee_id; 
            })
            .catch(error => {
                console.error('There was an error fetching the user data:', error);
            });
            },
        async fetchClients() {
            try {
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.get(`${apiDirectory}/get-privileged-clients`);
                this.clients = response.data;
            } catch (error) {
                // error
            }
        },
        filterClients() {
            if (this.client_name.length >= 0 && !this.isClientSelected) {
                const searchTerm = this.client_name.toLowerCase();

                this.filteredClients = this.clients.filter(client => {
                    const clientName = client.client_name.toLowerCase();
                    return clientName.includes(searchTerm);
            });
            } else {
                this.filteredClients = [];
            }
        },
        selectClient(client) {
            this.clientDetails = { ...client };
            this.client_name = client.client_name;
            this.client_id = client.id;
            this.filteredClients = [];
            this.isClientSelected = true;
            this.is_privileged = client.is_privileged;
        },
        toggleNewClientForm(){
            this.addNewClient = true;
            if (this.addNewClient) {
                this.isClientSelected = false;
            }
        },
        cancelNewClient() {
            this.addNewClient = false; 
        },
        closeDropdown() {
            this.filteredClients = [];
        },
        filterStates() {
            if (!this.searchState) {
            this.filteredStates = this.states;
            } else {
            this.filteredStates = this.states.filter((state) =>
                state.toLowerCase().includes(this.searchState.toLowerCase())
            );
            }
            this.isStateDropdownActive = true;
        },
        getFilteredStates(searchValue) {
            if (searchValue.length < 0) {
                return [];
            }
            return this.allStates.filter(state =>
                state.name.toLowerCase().includes(searchValue.toLowerCase())
            );
        },
        selectClientState(name) {
            this.client_state = name;
            this.filteredClientStates = [];
        },
        selectSourceState(name){
            this.source_state = name;
            this.filteredSourceStates = [];
        },
        selectDestinationState(name){
            this.destination_state = name;
            this.filteredDestinationStates = [];
        },
        backMainPanel() {
            this.$router.push('/main-panel');
        },
        showForm(formName) {
            this.currentForm = formName;
        },
        async saveNote() {
            try {
                this.sanitizeForm();
                this.saving_note = true;               
                const apiDirectory = process.env.API_DIRECTORY;
                const identifierResponse = await axios.post(`${apiDirectory}/create-identifier`, {
                    note_type: 4,
                });
                const noteIdent = identifierResponse.data.identifier;

                const quoteData = {
                    note_ident: noteIdent,
                    type: 4,
                    client: this.client_id,
                    request_time: new Date().toISOString(),
                    vehicle_brand: this.SelectedBrand,
                    vehicle_sub_brand: this.SelectedSubBrand,
                    vehicle_model: this.vehicle_model,
                    vehicle_plates: this.vehicle_plates,
                    vehicle_engine_series: this.vehicle_engine_series,
                    vehicle_color: this.vehicle_color,
                    insurance_policy: this.insurance_policy,
                    vehicle_owner_name: this.vehicle_owner_name,
                    file_number: this.file_number,
                    source: {
                        "street_name": this.source_street,
                        "number": this.source_street_number,
                        "between_streets": this.source_between_streets,
                        "neighborhood": this.source_neighborhood,
                        "city": this.source_city,
                        "zip_code": this.source_zip_code,
                        "state": this.source_state,
                        "lat": this.source_lat,
                        "lon": this.source_lon,
                    },
                    source_km: this.source_km,
                    source_notes: this.source_references,
                    destination: {
                        "street_name": this.destination_street,
                        "number": this.destination_street_number,
                        "between_streets": this.destination_between_streets,
                        "neighborhood": this.destination_neighborhood,
                        "city": this.destination_city,
                        "zip_code": this.destination_zip_code,
                        "state": this.destination_state,
                        "lat": this.destination_lat,
                        "lon": this.destination_lon,
                    },
                    destination_km: this.destination_km,
                    destination_notes: this.destination_references,
                    data_entry_clerk: this.employeeid,
                    cost_by_km: this.cost_by_km,
                    total_kms: this.total_kms,
                    maneuvers: this.maneuvers,
                    highway_tolls: this.highway_tolls,
                    subtotal: this.subtotal,
                    iva: this.iva,
                    retention: this.retention,
                    total: this.total,  
                    total_ovr: this.total_ovr,
                    payment_method: this.payment_method,
                    payment_type: this.payment_type,
                    invoice_use: this.invoice_use,
                    payment_notes: this.payment_notes,
                    status: 3,
                    lot: this.lot,
                    lot_retention: this.lot_retention,
                    lot_authority: this.lot_authority,
                    lot_start_time: this.lot_start_time,
                    lot_end_time: this.lot_end_time,
                    lot_cost_per_day: this.lot_cost_per_day,
                    lot_total_cost: this.lot_total_cost,
                    expected_time: this.expected_time,
                    sucursal: this.sucursal,
                    cost_by_traffic: this.cost_by_traffic

                };
                
                await axios.post(`${apiDirectory}/create-service-note`, quoteData, {
                    headers:
                    {
                        'Content-Type': 'application/json',
                    },
                });

                Swal.fire(
                    '¡Guardado!',
                    'Los datos del formulario se han guardado con éxito.',
                    'success'
                ).then(() => {
                    this.$router.push({ name: 'OperationsModule' });
                });
            } catch (error) {
                this.saving_note = false
                // Polygon condition and bad serializer cases
                console.error(error)
                if (error.response.status == 400) {

                    let isPolygon = false

                    if (error.response.data) {

                        const errorDataString = JSON.stringify(error.response.data);
                        try { isPolygon = errorDataString.includes("area") }
                        catch { isPolygon = false }

                    }

                    if (isPolygon) {

                        Swal.fire('Error', 'Verifique que la latitud y longitud sean válidas. \nLos datos que intentó ingresar no corresponden a una dirección dentro de México.', 'error');

                    }

                    else {

                        Swal.fire('Error', 'Verifique que los datos que está ingresando son válidos', 'error');

                    }

                }

                else {

                    Swal.fire('Error', 'Hubo un problema al actualizar los datos de la nota.', 'error');

                }
            }
        },

        async saveNewClient() {
            const clientData = {
                client_ident: 'test',
                client_name: this.client_name,
                client_address: {
                    street: this.clientDetails.client_address.street,
                    codigoPostal: this.clientDetails.client_codigoPostal,
                    neighborhood: this.client_neighborhood,
                    city: this.client_city,
                    state: this.client_state,
                },
                client_rfc: this.clientDetails.client_rfc,
                phone: this.clientDetails.phone,
                client_status: 1,
                is_privileged: this.is_privileged
                
            };

            try {
                // Validate clientData here (optional)

                // Replace 'http://yourbackend.api/clients' with your actual API endpoint
                console.log(clientData);
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.post(`${apiDirectory}/create-client`, clientData, {
                            headers:
                            {
                                'Content-Type': 'application/json',
                            },
                        });
                this.client_id = response.data.id;
                
                // If the request is successful
                Swal.fire('Guardado!', 'El nuevo cliente se guardó correctamente.', 'success');
                

            } catch (error) {
                console.error('There was an error saving the client:', error);
                Swal.fire('Error', 'Hubo un problema al guardar el cliente nuevo.', 'error');
            }
        },
        async FetchBrands() {
            /** Fetches the brands from the db an passes it to the brands prop */
            try {
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.get(`${apiDirectory}/get-brands`);
                // Tmp dict to hold the response after processing 
                const brandsDict = {};
                // Convert response into dict of form: {pk:brand}
                response.data.forEach(brand => {
                    //brandsDict[brand.pk] = brand.brand;  // Adjust based on actual keys in your data
                    brandsDict[brand.brand] = brand.pk;  // Adjust based on actual keys in your data
                });
                console.log(brandsDict)
                this.brands = brandsDict
            }
            catch (error) {
                console.error("Failed to fetch brands", error);
                console.log(error.response)
                if (error.response.status == 400) {
                    Swal.fire('Error', "Hubo un problema con la solicitud. Intentalo nuevamente.\n\
                    Si el problema persiste, contacte a su administrador.", 'error');
                    return
                }
                if (error.response.status == 500) {
                    Swal.fire('Error', "Ocurrió un error desconocido. Intentalo nuevamente.\n\
                    Si el problema persiste, contacte a su administrador.", 'error');
                }
            }
        },
        async FetchFilteredSubBrands(event){
            // Grab the value from the brand selector 
            this.subBrandLoading = true

            const selectedBrandPk = event.target.value;
            // Checks if there was a change in the brand. If so, reset the vue model value to null to prevent form submission until a conscius selection is made
            selectedBrandPk !== this.SelectedBrand ?  this.SelectedSubBrand = null : true;
            try { 
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.post(`${apiDirectory}/get-filtered-sub-brands`,{"brand_id":selectedBrandPk});
                // Tmp dict to hold the response after processing 
                const subBrandsDict = {};
                // Convert response into dict of form: {id:sub_brand + "Clase: "+ required_class}
                const classes={1:'A',2:'B',3:'C',4:'D'}
                response.data.forEach(sub_brand => {
                    subBrandsDict[sub_brand.sub_brand+' '+'-- Clase: '+classes[sub_brand.required_unit_type]] = sub_brand.id;  // Adjust based on actual keys in your data
                });
                this.sub_brands = subBrandsDict;
                this.subBrandLoading = false
            }
            catch (error) {
                console.error("Failed to fetch brands", error);
                console.log(error.response)
                if (error.response.status == 400) {
                    Swal.fire('Error', "Hubo un problema con la solicitud. Intentalo nuevamente. \
                    Si el problema persiste, contacte a su administrador.", 'error');

                }
                if (error.response.status == 500) {
                    Swal.fire('Error', "Ocurrió un error desconocido. Intentalo nuevamente. \
                    Si el problema persiste, contacte a su administrador.", 'error');
                }
            }
        },
        validatePositiveIntegerField(event) {
            /**Used to validate positive integer fields */
            if (event.charCode < 48 || event.charCode > 57) {
                event.preventDefault();
            }
        },
        validateFloatField(event) {
            const charCode = event.charCode;
            const value = event.target.value;

            const isDigit = charCode >= 48 && charCode <= 57;
            const isMinus = charCode === 45 && value.indexOf('-') === -1;
            const isDot = charCode === 46 && value.indexOf('.') === -1;

            if (!isDigit && !isMinus && !isDot) {
                event.preventDefault();
            }
        },
        sanitizeForm() {
            /**Used to sanitize values before submitting any changes */

            //Since not all notes require km, change empty str to null
            this.source_km == "" ? this.source_km = null : true
            this.destination_km == "" ? this.destination_km = null : true
            this.vehicle_model == "" ? this.vehicle_model = null : true
        },
    },
    mounted() {
        this.fetchClients();
        this.FetchBrands();
        // Any initializations you might want when the component is mounted
    },
}
</script>