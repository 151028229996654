<template>
    <div class="nav-bar-operations">
      <img src="@/assets/logogruas.png" alt="HomePage" class="logo-button"/>
    </div>
    <div class="welcome">
      <h3>Bienvenido/a, {{ firstName }}</h3>
      <button @click="logout">Cerrar sesión</button>
    </div>
    <div class="principal-options">
      <div class="wrapper" v-if="groups.includes('operaciones')">
        <h2>Operaciones</h2>
        <div class="card">
          <img src="@/assets/operations-card.jpg">
          <div class="info">
            <p>Órdenes de trabajo</p>
            <p>Monitoreo de unidades</p>
            <p>Reportes</p>
            <a href="./operations-module" class="btn">Entrar</a>
          </div>
        </div>
      </div>
      <div class="wrapper" v-if="groups.includes('mantenimiento')">
        <h2>Mantenimiento</h2>
        <div class="card">
          <img src="@/assets/tools-card.jpg">
          <div class="info">
            <p>Desgaste de unidades</p>
            <p>Órdenes de mantenimiento</p>
            <p>Métricas por unidad</p>
            <a href="./modulo-mantenimiento" class="btn">Entrar</a>
          </div>
        </div>
      </div>
      <div class="wrapper" v-if="groups.includes('facturacion')">
        <h2>Contabilidad</h2>
        <div class="card">
          <img src="@/assets/contability-card.jpg">
          <div class="info">
            <p>Cuentas por cobrar</p>
            <p>Facturación</p>
            <p>Estadísticos</p>
            <a href="./accounting-module" class="btn">Entrar</a>
          </div>
        </div>
      </div>
      <div class="wrapper" v-if="groups.includes('rh')">  <!--Cambiar a rh-->
        <h2>Personal</h2>
        <div class="card">
          <img src="@/assets/personal-card.jpg">
          <div class="info">
            <p>Registro de empleados</p>
            <p>Rendimiento y productividad</p>
            <p>Gestión de permisos</p>
            <a href="./modulo-personal" class="btn">Entrar</a>
          </div>
        </div>
      </div>
      <div class="wrapper" v-if="groups.includes('operaciones')">
        <h2>Reportes</h2>
        <div class="card">
          <img src="@/assets/report-card.jpg">
          <div class="info">
            <p> </p>
            <p>Catálogo general de reportes</p>
            <p> </p>
            <a href="./catalogo-reportes" class="btn">Entrar</a>
          </div>
        </div>
      </div>
      <div class="wrapper" v-if="groups.includes('owner')">
        <h2>Dashboard</h2>
        <div class="card">
          <img src="@/assets/dashboard-card.jpg">
          <div class="info">
            <p>Reporte general operaciones</p>
            <p>Reporte general personal</p>
            <p>Reporte general contabilidad</p>
            <a href="./under-construction" class="btn">Entrar</a>
          </div>
        </div>
      </div>
    </div>

</template>
  
<script>
import axios from 'axios'
  export default {
    name: 'MainPanel',
    data() {
    return {
      username: '',
      firstName: '',
      lastName: '',
      groups: []
    }
  },
  created() {
    this.fetchCurrentUser();
  },
    methods: {
      fetchCurrentUser() {
      const apiDirectory = process.env.API_DIRECTORY;
      axios.get(`${apiDirectory}/current-user/`, {
        headers: {
  'Authorization': 'Token ' + localStorage.getItem('authToken')
}
      })
      .then(response => {
        this.username = response.data.username;
        this.firstName = response.data.first_name;
        this.lastName = response.data.last_name;
        this.groups = response.data.groups;
      })
      .catch(error => {
        console.error('There was an error fetching the user data:', error);
      });
    },
      logout() {
        // Clear the token from the Vuex store
        this.$store.commit('setToken', null);
        // Remove the token from local storage
        localStorage.removeItem('authToken');
        // Redirect to the login page
        this.$router.push({ name: 'UserLogin' });
      }
    }
  };
</script>
  
<style>
.nav-bar-operations {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100vw; 
    height: 10vh; 
    background-color: #FFC434;
    z-index: 1000; 
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
}
.welcome{
  display: flex;
  justify-content: space-between; /* Aligns child elements to the end of the container */
  align-items: center; /* Center child elements vertically */
  margin-top: 100px;
  height: 4vh;
  border-bottom: 1px solid black;
  margin-left: 20px;
  margin-right: 20px;
}

.welcome button {
  padding: 5px;
  margin-right: 20px; /* Add a margin to the right to separate it from the border */
  background-color: #f00;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.recent {
  display: flex;
  margin: 20px 20px;
  justify-content: flex-start;
  
}

.recent h2 {
  color: black;
}
.recent-list{
  display: flex;
  
}
.recent-list ul {
  list-style: none;
}

.recent-list ul li {
  margin: 5px 0;
}

.principal-options {
  width: 100vw;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 85px;
  margin-top: 30px;
  justify-content: space-between;
}
.wrapper {
  margin: 0;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 0px;
}

.card {
  position: relative;
  width: 100%;
  padding: 2rem 1rem;
  background: #fff;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
  transition: 0.5s ease-in-out;
}

@media (max-width: 1200px) {
  .principal-options {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 992px) {
  .principal-options {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

@media (max-width: 768px) {
  .principal-options {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 576px) {
  .container {
    padding: 0 10px;
  }
  .welcome h3, .welcome button {
    margin: 0 5px;
  }
  .card {
    padding: 1rem 0.5rem;
  }
}

.card:hover{
  transform: translateY(20px);
}
.card::before{
  content:"";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgb(242, 255, 0));
  z-index: 2;
  transition: 0.5s all;
  opacity: 0;
}
.card:hover:before{
  opacity: 1;
}
.card img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.card .info{
  position: relative;
  z-index: 3;
  color: #fff;
  opacity: 0;
  transform: translateY(30px);
  transition: 0.5s all;
}
.card:hover .info{
  opacity: 1;
  transform: translateY(0px);
}
.card .info p{
  letter-spacing: 1px;
  font-size: 15px;
  margin-top: 8px;
  margin-bottom: 20px;
}
.card .info .btn{
  text-decoration: none;
  padding: 0.5rem 1rem;
  background: #fff;
  color: #000;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}
.card .info .btn:hover{
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
}

@media screen and (max-height: 586px) {
  .nav-bar-operations {
    height: 10vh;
  }
  .welcome {
    /* Adjust text spacing and size to accommodate smaller height */
    margin-top: 50px; /* Reduce as necessary */
    height: auto; /* Adjust whether elements overlap or do not fit */
    padding: 10px 0; /* Add some padding if necessary */
    flex-direction: row; /* Change direction to column if elements get too crowded */
    align-items: flex-start; /* Alignment at start if changed to column address */
  }
  .welcome h3 {
    font-size: 2rem;
  }
  .welcome button {
    padding: 8px;
    margin-top: 1px; /* Add a top margin if changing to column address */
  }
  .principal-options {
    /* Change the layout of the cards if there are space problems */
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px; /* Reduce the space between cards if necessary */
  }
  .wrapper {
    padding: 20px; /* Reduce padding if necessary */
  }
  .card {
    padding: 0.5rem; /* Adjust card padding */
  }
  .recent {
    margin: 10px 20px; /* Reduce margin if necessary */
  }
  .recent-list ul {
    padding: 20px; 
  }
}
@media screen and (min-height: 930px) {
  .welcome {
    /* Adjust text spacing and size to accommodate smaller height */
    margin-top: 130px; /* Reduce as necessary */
    height: auto; /* Adjust whether elements overlap or do not fit */
    padding: 10px 0; /* Add some padding if necessary */
    flex-direction: row; /* Change direction to column if elements get too crowded */
    align-items: flex-start; /* Alignment at start if changed to column address */
  }
}
</style>
