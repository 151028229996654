<template>
    <div class="nav-bar-operations">
      <img src="@/assets/logogruas.png" alt="HomePage" @click="backMainPanel" class="logo-button"/>
    </div>
    <div class="side-bar">
    <input type="text" v-model="searchQuery" placeholder="Buscar unidad por ID" />
    <div class="filter-results">
      <div v-for="unit in filteredLocations" :key="unit.vehicle_plate" @click="selectUnit(unit)">
        {{ unit.vehicle_name }}
      </div>
    </div>
  </div>
    <div class="map">
      <Map
        :center="mapCenter"
        :zoom="10"
        map-type-id="roadmap"
        style="width: 100vw; height: 90vh"
      >
        <Marker
          v-for="location in locations"
          :key="location.vehicle_plate"
          :position="{ lat: parseFloat(location.lat), lng: parseFloat(location.long) }"
          :icon="require('@/assets/grua-map-icon-marker.png')"
          @click="openInfoWindow(location)"
        />
        <!-- InfoWindow component -->
      <InfoWindow
        :options="{ content: infoContent }"
        :position="infoPosition"
        :opened="infoWindowOpened"
        @closeclick="infoWindowOpened = false"
      />
      </Map>
    </div>
</template>
  
  <script>
  import { ref, reactive, onMounted, nextTick, computed } from 'vue'
  import { Map, Marker, InfoWindow} from '@fawmi/vue-google-maps'
  import axios from 'axios';

  export default {
    components: {
      Map,
      Marker,
      InfoWindow
    },
    setup() {
      const locations = ref([])
      const mapCenter = ref({ lat: 25.66831199290133, lng: -100.2802354639708 }) // Adjust according to your needs
      const infoWindowOpened = ref(false);
      const infoContent = ref('');
      const infoPosition = reactive({ lat: 0, lng: 0 });
      const searchQuery = ref('')

      const selectUnit = (unit) => {
        mapCenter.value = { lat: parseFloat(unit.lat), lng: parseFloat(unit.long) };
        openInfoWindow(unit);
      }

      const filteredLocations = computed(() => {
        if (!searchQuery.value) {
          return locations.value;
        }
        return locations.value.filter((location) =>
          location.vehicle_name.toLowerCase().includes(searchQuery.value.toLowerCase())
        );
      });
      
      const openInfoWindow = (location) => {
  // Close the current InfoWindow to ensure reactivity is triggered when opening a new one
  infoWindowOpened.value = false;

  // Update the content for the InfoWindow
  infoContent.value = `
    <div>
      <h1>${location.vehicle_name}</h1>
      <p>Placas: ${location.vehicle_plate}</p>
      <p>Velocidad Actual: ${location.vehicle_speed} km/h</p>
    </div>
  `;

  // Update the position for the InfoWindow
  infoPosition.lat = parseFloat(location.lat);
  infoPosition.lng = parseFloat(location.long);

  // Use Vue's nextTick to wait for the next DOM update cycle before opening the new InfoWindow
  nextTick(() => {
    infoWindowOpened.value = true;
  });
};

      const fetchLocations = () => {
        const apiDirectory = process.env.API_DIRECTORY;
        axios.get(`${apiDirectory}/fetch-last-vehicle-locations`) // Adjust to match your backend URL
          .then(response => {
            locations.value = response.data;
          })
          .catch(error => {
            console.error('Error fetching locations:', error);
          });
      }
  
      onMounted(() => {
        fetchLocations();
        setInterval(fetchLocations, 20000); // Fetch vehicle locations every 20 seconds
      })
  
      return {
        locations,
        mapCenter,
        infoWindowOpened,
        infoContent,
        infoPosition,
        openInfoWindow,
        searchQuery,
        filteredLocations,
        selectUnit,
      }
    },
    methods: {
      backMainPanel() {
            window.location.href = '/main-panel';
        },
    }
  };
</script>
  
<style scoped>
.nav-bar-operations {
  position: absolute; 
  top: 0;
  left: 0;
  width: 100vw; 
  height: 10vh; 
  background-color: #FFC434;
  z-index: 1000; 
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  margin: 0;
}
  .side-bar {
    position: absolute;
    left: 0;
    top: 10vh; /* Align top with the nav-bar */
    width: 20vw; /* Adjust the width as needed */
    height: 90vh; /* Take up the rest of the height */
    background-color: #FFC434;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0,0,0,0.2);
    z-index: 1001; /* Make sure it's above the map */
    overflow-y: auto; /* Enable scrolling if the content is too long */
  }

  .side-bar input {
    width: 15vw;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .filter-results div {
    cursor: pointer;
    padding: 10px;
    border-bottom: 2px solid #0a0a0a;
  }

  .filter-results div:hover {
    background-color: #a09607;
  }

  /* Adjust the map class to account for the new sidebar */
  .map {
    position: absolute;
    top: 10vh;
    left: 20vw; /* Start the map right beside the sidebar */
    width: 80vw; /* Adjust width to fill the remaining space */
    height: 90vh;
  }
  </style>
