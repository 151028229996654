<template>
    <div>
      <h1>Fetch and Display Images</h1>
      
      <div>
        <label for="object_id">Object ID:</label>
        <input v-model="objectId" type="text" id="object_id" />
  
        <label for="object_type">Object Type:</label>
        <input v-model="objectType" type="text" id="object_type" />
  
        <button @click="fetchFiles">Submit</button>
      </div>
      
      <div v-if="loading">Loading...</div>
  
      <div v-if="files.length > 0">
        <div v-for="file in files" :key="file.file_id">
          <img :src="'data:image/jpeg;base64,' + file.content" alt="Image" />
        </div>
      </div>
      <div v-if="error" class="error">{{ error }}</div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        objectId: '',
        objectType: '',
        files: [],
        loading: false,
        error: null
      };
    },
    methods: {
      async fetchFiles() {
        this.loading = true;
        this.error = null;
        
        try {
          const response = await fetch(`http://127.0.0.1:8000/api/get-files/?object_id=${this.objectId}&object_type=${this.objectType}`);
          
          if (!response.ok) {
            throw new Error('Failed to fetch files');
          }
          
          const data = await response.json();
          this.files = data;
        } catch (err) {
          this.error = err.message;
        } finally {
          this.loading = false;
        }
      }
    }
  };
  </script>
  
  <style scoped>
  img {
    max-width: 100%;
    height: auto;
    margin: 10px;
  }
  .error {
    color: red;
  }
  </style>
  