<template>
  <div>
    <label for="object_id">Object ID:</label>
    <input v-model="objectId" type="text" id="object_id" />

    <label for="object_type">Object Type:</label>
    <input v-model="objectType" type="text" id="object_type" />

    <input type="file" @change="onFileChange">
    <button @click="uploadFile">Upload</button>
    
    <div v-if="fileId">
      <a :href="fileUrl" target="_blank">Download File</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      file: null,
      fileId: '',
      fileUrl: '',
      objectId: '',
      objectType: ''
    }
  },
  methods: {
    onFileChange(event) {
      this.file = event.target.files[0];
    },
    async uploadFile() {
      if (!this.file) {
        alert("Please select a file");
        return;
      }

      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('object_id', this.objectId);
      formData.append('object_type', this.objectType);

      try {
        const apiDirectory = process.env.API_DIRECTORY || 'http://127.0.0.1:8000/api';  // Use fallback for local testing
        const response = await fetch(`${apiDirectory}/upload-file`, {
          method: 'POST',
          body: formData
        });

        const data = await response.json();
        this.fileId = data.file_id;
        
        // Use the new get-files endpoint to retrieve the file URL
        const fileResponse = await fetch(`${apiDirectory}/get-files/?object_id=${this.objectId}&object_type=${this.objectType}`);
        const fileData = await fileResponse.json();

        // Assuming fileData contains a field 'url' with the file URL
        if (fileData.length > 0) {
          this.fileUrl = fileData[0].url;
        } else {
          this.fileUrl = '';
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  }
}
</script>
