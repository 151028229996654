<template>
  <div>
    <!-- Dynamically render the form component based on the note type -->
    <component v-if="userFetched && formComponent" :is="formComponent" :noteDetails="noteDetails" :assignations="assignations"
     :username="username" :user_id="user_id"></component>
  </div>
</template>

<style>
.custom-loader {
  position: fixed;
  margin-left: 25vw;
  width: 50vw;
  height: 5vh;
  border-radius: 20px;
  color: #F4BF00;
  border: 2px solid;
}
.custom-loader::before {
  content: "";
  position: absolute;
  margin: 2px;
  inset: 0 100% 0 0;
  border-radius: inherit;
  background: #F4BF00;
  animation: p6 2s infinite;
}
.text-loading-edit-note{
  margin-top: 40vh;
  font-size: 2em;
}

@keyframes p6 {
  100% {
    inset: 0
  }
}
</style>

<script>
import axios from 'axios';
import EditAssistanceNote from './EditAssistanceNote.vue'; // Example of a form component for a specific type
import EditAuxiliarNote from './EditAuxiliarNote.vue';
import EditForkliftNote from './EditForkliftNote.vue';
import EditLotNote from './EditLotNote.vue';
// Import other form components

export default {
  components: {
    EditAssistanceNote,
    EditAuxiliarNote,
    EditForkliftNote,
    EditLotNote,
    // Register other form components here
  },
  data() {
    return {
      noteDetails: null, // Will hold the fetched note details
      noteType: '', // To dynamically determine which form to use
      assignations: [],
      recommendations: {},
      username : '',
      user_id : null,
      userFetched: false // Indicates if the current user has been fetched
    };
  },
  computed: {
    formComponent() {
      // Determine which component to render based on the note type
      console.log(this.noteType)
      switch (this.noteType) {
        case 1:
          return 'EditAuxiliarNote';
        case 2:
          return 'EditAssistanceNote';
        case 3:
          return 'EditForkliftNote';
        case 4:
          return 'EditLotNote';
        // case for other types...
        default:
          return null; // or a default form component if you have one
      }
    },
  },
  async mounted() {
    await this.fetchNoteDetails();
    await this.fetchAssignations();
    await this.fetchCurrentUser();
  },
  methods: {
    async fetchNoteDetails() {
      const noteID = this.$route.params.noteID; // Get noteID from route params
      console.log(noteID,'this is the note id i got and will determine the endpoint i call')
      // Determine which endpoint to call based on the route params
      if (noteID.includes("MTC")){
        try {
        const apiDirectory = process.env.API_DIRECTORY;
        const response = await axios.get(`${apiDirectory}/forklift-notes/` + noteID + "/");
        this.noteDetails = response.data;
        this.noteType = 3; // Assuming the response includes a type field
      } catch (error) {
        console.error("Failed to fetch note details:", error);
      }
      }
      else{
      try {
        const apiDirectory = process.env.API_DIRECTORY;
        const response = await axios.get(`${apiDirectory}/notes/` + noteID + "/");
        this.noteDetails = response.data;
        this.noteType = response.data.type; // Assuming the response includes a type field
      } catch (error) {
        console.error("Failed to fetch note details:", error);
        // Handle error (e.g., show user feedback)
      }
    }
    },
    async fetchAssignations() {
      const noteID = this.$route.params.noteID; // Get noteID from route params
      try {
        const apiDirectory = process.env.API_DIRECTORY;
        const response = await axios.post(`${apiDirectory}/get-note-assignations`, { "note_ident": noteID });
        this.assignations = response.data;
        //console.log("assignations are", this.assignations)
      }
      catch (error) {
        console.error("Failed to fetch assignations:", error);
      }
    },
    fetchCurrentUser() {
      /** Fetches the current user data, used to fill in who is making an assignation */
      const apiDirectory = process.env.API_DIRECTORY;
      axios.get(`${apiDirectory}/current-user/`, {
        headers: {
          'Authorization': 'Token ' + localStorage.getItem('authToken')
        }
      })
        .then(response => {
          //this.username = response.data.username;
          //this.firstName = response.data.first_name;
          //this.lastName = response.data.last_name;
          //this.groups = response.data.groups;
          this.user_id = response.data.id;
          this.userFetched = true; // Set userFetched to true once user data is fetched
        })
        .catch(error => {
          console.error('There was an error fetching the user data:', error);
        });
    }
  },

};
</script>
