<template>
    <div class="map">
      <div id="map" style="width: 100%; height: 100%"></div>
    </div>
  </template>
  
  <script>
  /* global google */
 
  import {  onMounted } from 'vue';
  
  export default {
    setup() {
      const mapCenter = { lat: 40.7128, lng: -74.006 }; 
      const origin = { lat: 40.7128, lng: -74.006}; //change origin
      const destination = { lat: 25.651845, lng: -100.291815}; //change destination
  
      // Load Google Maps API dynamically
      onMounted(() => {
        const script = document.createElement('script');
        const apiKey = 'AIzaSyDttJadFf5cWEcGQMFmRZCrjzUFony74xI'; //change api-key
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
          // Once the script is loaded, initialize the map and directions service
          initializeMap();
        };
        document.head.appendChild(script);
      });
  
      // Function to initialize the map and directions service
      const initializeMap = () => {
        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer({
          polylineOptions: {
            strokeColor: 'violet'
          },
          map: new google.maps.Map(document.getElementById('map'), {
            zoom: 10,
            center: mapCenter
          })
        });
  
        const request = {
          origin: origin,
          destination: destination,
          travelMode: 'DRIVING'
        };
  
        directionsService.route(request, (result, status) => {
          if (status === 'OK') {
            directionsRenderer.setDirections(result);
          } else {
            console.error('Error fetching route:', status);
          }
        });
      };
  
      return {
        mapCenter
      };
    }
  };
  </script>
  
  <style scoped>
  .map {
    position: absolute;
    top: 10vh;
    width: 100%;
    height: 90vh;
  }
  </style>
  
  