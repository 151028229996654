<template>
  <div id="app">
    <!-- This will render the component based on the route -->
    <router-view></router-view>
  </div>
</template>

<script>
// No need to import UserLogin here since it will be handled by the router

export default {
  name: 'App',
  // No need to register UserLogin in components since it's handled by router
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
