import { createStore } from 'vuex';

const store = createStore({
    state(){
        return{
            token: localStorage.getItem('authToken'),
        };
    },
    mutations: {
        setToken(state, token){
            state.token = token;
        },
    },
    actions: {

    },
    getters: {
        isAuthenticated(state){
            return !!state.token;
        }, 
    },
    modules: {

    }
});

export default store;