<template>
    <div class="nav-bar-operations">
        <img src="@/assets/logogruas.png" alt="HomePage" @click="backMainPanel" class="logo-button"/>
        </div>
    <div class="text-prices">
        <h2 class="order-type">Tabla de cotizaciones</h2>
    </div>

    <!-- Table section -->
    <div class="table-container">
        <table class="table">
            <thead>
                <tr>
                <th>ID <input v-model="filters.ident" @input="applyFilters" placeholder="Search ID" /></th>
                <th>Tiempo <input v-model="filters.obs_time" @input="applyFilters" placeholder="Search Tiempo" /></th>
                <th>Nombre(s) <input v-model="filters.first_name" @input="applyFilters" placeholder="Search Nombre(s)" /></th>
                <th>Apellidos <input v-model="filters.last_name" @input="applyFilters" placeholder="Search Apellidos" /></th>
                <th>Compañía <input v-model="filters.company" @input="applyFilters" placeholder="Search Compañía" /></th>
                <th>Teléfono <input v-model="filters.phone" @input="applyFilters" placeholder="Search Teléfono" /></th>
                <th>Categoría <input v-model="filters.category" @input="applyFilters" placeholder="Search Categoría" /></th>
                <th>Modelo <input v-model="filters.model" @input="applyFilters" placeholder="Search Modelo" /></th>
                <th>Origen <input v-model="filters.source" @input="applyFilters" placeholder="Search Origen" /></th>
                <th>Destino <input v-model="filters.destination" @input="applyFilters" placeholder="Search Destino" /></th>
                <th>Notas <input v-model="filters.notes" @input="applyFilters" placeholder="Search Notas" /></th>
                <th>Distancia en KM <input v-model="filters.distance_km" @input="applyFilters" placeholder="Search Distancia en KM" /></th>
                <th>Tiempo estimado <input v-model="filters.estimated_time" @input="applyFilters" placeholder="Search Tiempo estimado" /></th>
                <th>Precio viaje <input v-model="filters.total_price" @input="applyFilters" placeholder="Search Precio viaje" /></th>
                <th>Casetas <input v-model="filters.highway_tolls" @input="applyFilters" placeholder="Search Casetas" /></th>
                <th>Maniobras <input v-model="filters.maneuvers" @input="applyFilters" placeholder="Search Maniobras" /></th>
                <th>Tiempo de espera <input v-model="filters.waiting_time" @input="applyFilters" placeholder="Search Tiempo de espera" /></th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="(order, index) in paginatedOrders" :key="index">
                    <td>
                        <span style="background-color: #FFC434; color: black; font-weight: bold; padding: 2px 5px; border-radius: 3px;">{{ order.ident }}</span>
                    </td> 
                    <td>{{ order.obs_time }}</td> 
                    <td>{{ order.first_name }}</td>
                    <td>{{ order.last_name }}</td> 
                    <td>{{ order.company }}</td>
                    <td>{{ order.phone }}</td> 
                    <td>{{ order.category }}</td> 
                    <td>{{ order.model }}</td> 
                    <td>{{ order.source }}</td> 
                    <td>{{ order.destination }}</td> 
                    <td>{{ order.notes }}</td> 
                    <td>{{ order.distance_km }}</td> 
                    <td>{{ order.estimated_time }}</td>
                    <td>{{ order.total_price }}</td>
                    <td>{{ order.highway_tolls }}</td>
                    <td>{{ order.maneuvers }}</td>
                    <td>{{ order.waiting_time }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    
    <div class="pagination-container">
        <button @click="prevPage" :disabled="currentPage === 1"> 
            <img src="@/assets/angulo-izquierdo.png" alt="Previous Page" />
        </button>
        <span>Página {{ currentPage }} de {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage >= totalPages">
            <img src="@/assets/angulo-derecho.png" alt="Next Page" />
        </button>
    </div>
</template>

<style>
.nav-bar-operations {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100vw; 
    height: 10vh; 
    background-color: #FFC434;
    z-index: 1000; 
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
}
.logo-button {
    position: absolute; 
    height: 90%; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    margin: 0;
}
.text-prices{
    display: flex;
    justify-content: center;
    margin-top: 11vh;
}
.table-container {
    flex-grow: 1;
    overflow-x: auto;
    margin-top: 1vh; 
    padding: 1vh; 
}
.table {
  width: 100%;
  border-collapse: collapse;
}
.table th, .table td {
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
}
.table th, .table td {
  border-left: none; 
  border-right: none; 
  padding: 8px;
  text-align: left;
  font-size: 0.9em;
}
.table tr {
  border-left: none; 
  border-right: none;
}
.table tbody tr:first-child td {
  border-top: none;
}
.table tbody tr:hover td{
    background-color: #bdbdbd;
    color: rgb(0, 0, 0);
}
/* Style for the last row of the tbody */
.table tbody tr:last-child td {
  border-bottom: none;
}
.pagination-container {
    justify-content: center;
    align-items: center;
}
.pagination-container button {
  padding: 5px 10px;
  border: 1px solid #000;
  background-color: #fff;
  cursor: pointer;
  margin: 0 10px;
  background-color: transparent; 
  border: none; 
}
.pagination-container button:disabled {
  color: #ccc;
  cursor: not-allowed;
}
.pagination-container span {
  font-size: 16px;
}
.pagination-container button img {
  width: 25px; 
  height: auto; 
}
@media (max-width: 768px) {
    .search-and-filter, .action-buttons {
        flex-direction: column;
        align-items: stretch;
    }
}
@media screen and (min-width: 768px) {
  .table th, .table td {
    width: 8%; 
  }
}
</style>

<script>
import axios from 'axios';

export default {
    data() {
        return{
            filters: {
            ident: '',
            obs_time: '',
            first_name: '',
            last_name: '',
            company: '',
            phone: '',
            category: '',
            model: '',
            source: '',
            destination: '',
            notes: '',
            distance_km: '',
            estimated_time: '',
            total_price: '',
            highway_tolls: '',
            maneuvers: '',
            waiting_time: ''
        },
            currentPage: 1,
            pageSize: 10,
            ordersPQ: [],
            originalOrdersPQ: [],
        }
    },
    computed: {
        paginatedOrders() {
            const start = (this.currentPage - 1) * this.pageSize;
            const end = start + this.pageSize;
            return this.ordersPQ.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.ordersPQ.length / this.pageSize);
        }
    },
    mounted() {
        this.fetchOrders();
    },
    methods: {
        backMainPanel() {
            window.location.href = '/main-panel';
        },
        applyFilters() {
            const filteredOrders = this.originalOrdersPQ.filter(order => {
                return Object.keys(this.filters).every(key => {
                    const filterValue = this.filters[key].toLowerCase().trim();
                    const orderValue = order[key] !== null && order[key] !== undefined ? order[key].toString().toLowerCase() : '';
                    console.log(`Key: ${key}, Filter Value: ${filterValue}, Order Value: ${orderValue}`);
                    return filterValue === '' || orderValue.includes(filterValue);
                });
            });
            this.currentPage = 1;
            this.ordersPQ = filteredOrders;
        },
        fetchOrders() {
            // Make a GET request to your API endpoint
            const apiDirectory = process.env.API_DIRECTORY;
            axios.get(`${apiDirectory}/get-pricing-quotes`)
                .then(response => {
                    // Assuming your API response contains the data in a property called 'data'
                    const responseData = response.data;
                    // Iterate through the response data and add dummy data for missing columns
                    responseData.forEach(orderPQ => {
                        // Check if 'origen' property exists and if it's not undefined
                        if (!('Salida' in orderPQ) || orderPQ.salida === undefined) {
                            // Add dummy value for 'origen' if missing
                            orderPQ.salida = 'N/A';
                        }
                        if (!('Inicio' in orderPQ) || orderPQ.inicio === undefined) {
                            // Add dummy value for 'origen' if missing
                            orderPQ.inicio = 'N/A';
                        }
                        if (!('Unidad' in orderPQ) || orderPQ.unidad === undefined) {
                            // Add dummy value for 'origen' if missing
                            orderPQ.unidad = 'N/A';
                        }
                        if (!('Operador' in orderPQ) || orderPQ.operador === undefined) {
                            // Add dummy value for 'origen' if missing
                            orderPQ.operador = 'N/A';
                        }
                        if (!('Status' in orderPQ) || orderPQ.status === undefined) {
                            // Add dummy value for 'origen' if missing
                            orderPQ.status = 'N/A';
                        }
                        // Repeat the above process for other missing columns
                    });
                    this.ordersPQ = responseData;
                    this.originalOrdersPQ = [...responseData]; // Store a copy of the original orders
                })
                .catch(error => {
                    console.error('Error fetching data from API:', error);
                });
        },
        nextPage() {
            if (this.currentPage < this.totalPages) this.currentPage++;
        },
        prevPage() {
            if (this.currentPage > 1) this.currentPage--;
        },
    }
}
</script>