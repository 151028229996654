<template>
    <div class="nav-bar-operations">
        <img src="@/assets/logogruas.png" alt="HomePage" class="logo-button"/>
    </div>
    <div class="welcome-operator">
        <h2>Interfaz de operador</h2>
    </div>
    <div class="welcome-and-logout">
        <h3>Bienvenido/a, {{ firstName }}</h3>
        <button @click="logout">Cerrar sesión</button>
    </div>
    <!-- Table section -->
    <div class="table-container-operator">
        <table class="table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Origen</th>
                    <th>Destino</th>
                    <th>Tipo de nota</th>
                    <th>Datos del vehículo</th>
                    <th>Referencias de origen</th>
                    <th>Referencias de destino</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(assignation, index) in paginatedOrders" :key="index">
                    <td @click="navigateToOrderDetails(assignation.id)">
                        <span class="id-span">{{ assignation.assistance_note.id }}</span>
                    </td>
                    <td><b>Calle: </b>{{ assignation.assistance_note.source.street_name }}<br> <b>Número: </b>{{ assignation.assistance_note.source.number }}<br> <b>Entre calles: </b>{{ assignation.assistance_note.source.between_streets }}<br> <b>Colonia: </b>{{ assignation.assistance_note.source.neighborhood }}<br> <b>Ciudad: </b>{{ assignation.assistance_note.source.city }}<br> <b>Estado: </b>{{ assignation.assistance_note.source.state }}</td>
                    <td><b>Calle: </b>{{ assignation.assistance_note.destination.street_name }}<br> <b>Número: </b>{{ assignation.assistance_note.destination.number }}<br> <b>Entre calles: </b>{{ assignation.assistance_note.destination.between_streets }}<br> <b>Colonia: </b>{{ assignation.assistance_note.destination.neighborhood }}<br> <b>Ciudad: </b>{{ assignation.assistance_note.destination.city }}<br> <b>Estado: </b>{{ assignation.assistance_note.destination.state }}</td>
                    <td>{{ assignation.assistance_note_type }}</td>
                    <td><b>Marca: </b>{{ assignation.assistance_note_brand }}<br><b>Submarca: </b>{{ assignation.assistance_note_subbrand }}<br><b>Color: </b>{{ assignation.assistance_note.vehicle_color }}<br><b>Placas: </b>{{ assignation.assistance_note.vehicle_plates }}<br></td>
                    <td><b>Referencias: </b>{{ assignation.assistance_note.source_notes }}<br></td>
                    <td><b>Referencias: </b>{{ assignation.assistance_note.destination_notes }}<br></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="pagination-container">
        <button @click="prevPage" :disabled="currentPage === 1"> 
            <img src="@/assets/angulo-izquierdo.png" alt="Previous Page" />
        </button>
        <span>Página {{ currentPage }} de {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage >= totalPages">
            <img src="@/assets/angulo-derecho.png" alt="Next Page" />
        </button>
    </div>
</template>

<style>
.nav-bar-operations {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100vw; 
    height: 10vh; 
    background-color: #FFC434;
    z-index: 1000; 
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
}
.logo-button {
    position: absolute; /* Position the logo absolutely within the navigation bar */
    /* Make the logo cover the entire width of the navigation bar */
    height: 90%; /* Make the logo cover the entire height of the navigation bar */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    margin: 0;
}
.welcome-and-logout{
  display: flex;
  justify-content: space-between; /* Aligns child elements to the end of the container */
  align-items: center; /* Center child elements vertically */
  margin-left: 10px;
}

.welcome-and-logout button {
  padding: 5px;
  margin-right: 20px; /* Add a margin to the right to separate it from the border */
  background-color: #f00;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.id-span {
  cursor: pointer;
  background-color: #FFC434;
  color: black;
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 3px;
}
.table-container-operator {
    flex-grow: 1;
    overflow-x: auto;
    margin-top: none; /* Adjust as needed */
    padding: 10px; /* Adjust as needed */
}
.table {
  width: 100%;
  border-collapse: collapse;
}
.table th, .table td {
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
}
.table th, .table td {
  border-left: none; /* Remove left border */
  border-right: none; 
  padding: 8px;
  text-align: left;
  font-size: 0.9em;
}
.table tr {
  border-left: none; 
  border-right: none; 
}
.table tbody tr:first-child td {
  border-top: none;

}
.table tbody tr:hover td{
    background-color: #bdbdbd;
    color: rgb(0, 0, 0);
}
.table tbody tr:last-child td {
  border-bottom: none;
}
.pagination-container {
    justify-content: center;
    align-items: center;
}
.pagination-container button {
  padding: 5px 10px;
  border: 1px solid #000;
  background-color: #fff;
  cursor: pointer;
  margin: 0 10px;
  background-color: transparent; /* You can make the background transparent */
  border: none; 
}
.pagination-container button:disabled {
  color: #ccc;
  cursor: not-allowed;
}
.pagination-container span {
  font-size: 16px;
}
.pagination-container button img {
  width: 25px; 
  height: auto; /* This will maintain the aspect ratio of the image */
}
.welcome-operator{
    margin-top: 12vh;
}
</style>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            currentPage: 1,
            pageSize: 10,
            assignations: [],
            username: '',
            firstName: '',
            lastName: '',
        };
    },
    computed: {
        paginatedOrders() {
            const start = (this.currentPage - 1) * this.pageSize;
            const end = start + this.pageSize;
            return this.assignations.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.assignations.length / this.pageSize);
        }
    },
    mounted() {
        this.fetchAssignations();
        this.fetchCurrentUser();
    },
    methods:{
        toggleFilterMenu() {
            this.showFilterMenu = !this.showFilterMenu;
        },
        applyFilters() {
            console.log('Filtros aplicados:', this.selectedFilters);
            this.showFilterMenu = false; 
        },
        nextPage() {
            if (this.currentPage < this.totalPages) this.currentPage++;
        },
        prevPage() {
            if (this.currentPage > 1) this.currentPage--;
        },
        async fetchAssignations() {
            try {
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.get(`${apiDirectory}/get-assignations-operator`, {
                    headers: {
                        'Authorization': `Token ${localStorage.getItem('authToken')}` 
                    }
                });
                 this.assignations = response.data
                 .filter(assignation => assignation.assistance_note !== null)
                 .sort((a, b) => b.id - a.id);
            } catch (error) {
                console.error('Failed to fetch assignations:', error);
            }
        },
        navigateToOrderDetails(assignation_id) {
            this.$router.push({ name: 'EditAssignation', params: { assignation_id } });
        },
        fetchCurrentUser() {
            const apiDirectory = process.env.API_DIRECTORY;
            axios.get(`${apiDirectory}/current-user/`, {
                headers: {
                    'Authorization': 'Token ' + localStorage.getItem('authToken')
                }
            })
            .then(response => {
                this.username = response.data.username;
                this.firstName = response.data.first_name;
                this.lastName = response.data.last_name;
                this.groups = response.data.groups;
            })
            .catch(error => {
                console.error('There was an error fetching the user data:', error);
            });
        },
        logout() {
            // Clear the token from the Vuex store
            this.$store.commit('setToken', null);
            // Remove the token from local storage
            localStorage.removeItem('authToken');
            // Redirect to the login page
            this.$router.push({ name: 'UserLogin' });
        }
    }
}
</script>