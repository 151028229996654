<template>
  <div>
    <!-- Dynamically render the form component based on the note type -->
    <component :is="formComponent" :employeeDetails="employeeDetails"></component>
  </div>
</template>

<script>
import axios from 'axios';
import EditEmployeeProfile from './EditEmployeeProfile.vue'; // Example of a form component for a specific type
// Import other form components

export default {
  components: {
    EditEmployeeProfile,
    // Register other form components here
  },
  data() {
    return {
      employeeDetails: "",
    };
  },
  computed: {
    formComponent() {
      return 'EditEmployeeProfile';
    },
  },
  async mounted() {
    await this.fetchProfileDetails();
  },
  methods: {
    async fetchProfileDetails() {
      const employee_ident = this.$route.params.employee_ident;
      console.log(employee_ident)
      try {
        const apiDirectory = process.env.API_DIRECTORY;
        const response = await axios.get(`${apiDirectory}/employee_detail/` + employee_ident + "/");
        this.employeeDetails = response.data;
      } catch (error) {
        console.error("Failed to fetch employee details:", error);
        // Handle error (e.g., show user feedback)
      }
    }
  }
};
</script>