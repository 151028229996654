<template>
    <div>
      <!-- Dynamically render the form component based on the note type -->
      <component :is="formComponent" :unitDetails="unitDetails"></component>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import EditUnitDetails from './EditUnitDetails.vue'; // Example of a form component for a specific type
  // Import other form components
  
  export default {
    components: {
      EditUnitDetails,
      // Register other form components here
    },
    data() {
      return {
        unitDetails: "",
      };
    },
    computed: {
      formComponent() {
        return 'EditUnitDetails';
      },
    },
    async mounted() {
      await this.fetchUnitProfileDetails();
    },
    methods: {
      async fetchUnitProfileDetails() {
        const unit_id = this.$route.params.unit_id;
        console.log(unit_id)
        try {
          const apiDirectory = process.env.API_DIRECTORY;
          const response = await axios.get(`${apiDirectory}/unit_detail/` + unit_id + "/");
          this.unitDetails = response.data;
        } catch (error) {
          console.error("Failed to fetch unit details:", error);
          // Handle error (e.g., show user feedback)
        }
      }
    }
  };
  </script>