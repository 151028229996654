<template>
    <div class="nav-bar-rh">
        <img src="@/assets/logogruas.png" alt="HomePage" @click="backMainPanel" class="logo-button"/>
    </div>
    <div class="action-buttons-rh">
        <button @click="addEmployee" class="action-button-rh">Agregar nuevo personal</button>
    </div>

    <div class="table-container-rh">
        <h4>Tabla del personal</h4>
        <table class="table">
            <thead>
                <tr>
                    <th>ID <input v-model="filters.employee_ident" @input="applyFiltersEmployee" placeholder="Buscar por ID" /></th>
                    <th>Nombre Completo <input v-model="filters.employee_first_name" @input="applyFiltersEmployee" placeholder="Buscar por nombre" /></th>
                    <th>Posición <input v-model="filters.employee_position" @input="applyFiltersEmployee" placeholder="Buscar por posición" /></th>
                    <th>Departamento <input v-model="filters.employee_department" @input="applyFiltersEmployee" placeholder="Buscar por departamento" /></th>
                    <th>Estatus <input v-model="filters.employee_status" @input="applyFiltersEmployee" placeholder="Buscar por estatus" /> </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(employee, index) in paginatedEmployees" :key="index"
                    @click="viewEmployeeDetails(employee.employee_ident)">
                    <td><span style="background-color: #FFC434; color: black; font-weight: bold; padding: 2px 5px; border-radius: 3px;">{{employee.employee_ident }}</span></td>
                    <td>{{ employee.employee_first_name }} {{ employee.employee_last_name }} {{ employee.employee_last_name2 }}</td>
                    <td>{{ employee.employee_position }}</td>
                    <td>{{ employee.employee_department }}</td>
                    <td>
                        <span v-if="employee.employee_status === 'Activo'" style="background-color: green; color: white; padding: 2px 5px; border-radius: 3px;">Activo</span>
                        <span v-else-if="employee.employee_status === 'Inactivo'" style="background-color: red; color: white; padding: 2px 5px; border-radius: 3px;">Inactivo</span>
                        <span v-else>{{ employee.employee_status }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="pagination-container">
            <button @click="prevPage" :disabled="currentPage === 1">
                <img src="@/assets/angulo-izquierdo.png" alt="Previous Page" />
            </button>
            <span v-for="pageNumber in totalEmployeesPages" :key="pageNumber" @click="goToPage(pageNumber)"
                :class="{ active: pageNumber === currentPage }">{{ pageNumber }}</span>
            <button @click="nextPage" :disabled="currentPage >= totalEmployeesPages">
                <img src="@/assets/angulo-derecho.png" alt="Next Page" />
            </button>
        </div>

    </div>
</template>

<style>
.nav-bar-rh {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10vh;
    background-color: #FFC434;
    z-index: 1000;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
}
.logo-button {
    position: absolute;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    margin: 0;
}
.table-container-rh {
    flex-grow: 1;
    overflow-x: auto;
    margin-top: 1vh;
    padding: 1vh;
}
.table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.6em; 
}
.table th, .table td {
    border-left: none;
    border-right: none;
    padding: 5px; 
    text-align: left;
}
.table tbody tr:first-child td {
    border-top: none;
}
.table tbody tr:hover td {
    background-color: #bdbdbd;
    color: rgb(0, 0, 0);
}
.table tbody tr:last-child td {
    border-bottom: none;
}
.table thead input {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    margin: 0;
    border-radius: 5px;
    border-width: 1px;
}
.table th{
    border-left: none;
    border-right: none;
    padding: 10px;
    text-align: left
}
.table td {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}
.action-buttons-rh {
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    align-items: center;
    margin-top: 13vh; 
    height: 3vh; 
}
.action-button-rh {
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color: #FFC434;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
    margin: 0 10px; 
}
.action-button-rh:hover {
    background-color: #dba907;
}
@media (max-width: 768px) {
    .action-buttons {
        flex-direction: column;
        align-items: stretch;
    }
}
@media screen and (min-width: 768px) {
    .table th, .table td {
        width: 8%; 
    }
}
</style>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            currentPage: 1,
            pageSize: 12,
            employees : [],
            n_employees: 0,
            filters: {
                employee_ident: '',
                employee_first_name: '',
                employee_position: '', //["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16"],
                employee_department: '',
                employee_status: '' //["1","2"],
            },
            originalEmployees: []
        }
    },
    computed: {
        paginatedEmployees() {
            return this.employees
        },
        totalEmployeesPages() {
            return Math.ceil(this.n_employees / this.pageSize);
        }
    },
    mounted() {
        this.fetchEmployees();
    },
    methods: {
        backMainPanel() {
            window.location.href = '/main-panel';
        },
        addEmployee() {
            window.location.href = '/agregar-nuevo-personal';
        },
        goToPage(pageNumber) {
            this.currentPage = pageNumber;
            const page = this.currentPage
            this.fetchEmployees(page);
        },
        async fetchEmployees(page = 1) {
            /**Fetches the in process notes using the provided filters*/
            let response
            const apiDirectory = process.env.API_DIRECTORY;

            try {
                response = await axios.post(`${apiDirectory}/get-employee?page=${page}&page_size=${this.pageSize}`,this.filters)
                const responseData = response.data.results;
                this.employees = responseData
                this.n_employees = response.data.count
                this.originalEmployees = [...responseData]
            }
            catch (error) {
                console.error('Error fetching data from API:', error)
            }
        },
        nextPage() {
            if (this.currentPage < this.totalEmployeesPages) this.currentPage++;
            const page = this.currentPage
            this.fetchEmployees(page);
        },
        prevPage() {
            if (this.currentPage > 1) this.currentPage--;
            const page = this.currentPage
            this.fetchEmployees(page);
        },
        applyFiltersEmployee() {
            this.fetchEmployees()
        },
        viewEmployeeDetails(employee_ident) {
            Swal.fire({
                title: '¿Estás seguro?',
                text: `¿Quieres ver los detalles del personal con el ID ${employee_ident}?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, ver detalles',
                cancelButtonText: 'No, cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$router.push({ name: 'EditProfile', params: { employee_ident } });
                }
            });
        },
    }
}
</script>