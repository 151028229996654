<template>
    <div>
      <!-- Dynamically render the form component based on the note type -->
      <component :is="formComponent" :clientDetails="clientDetails"></component>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import EditClientProfile from './EditClientProfile.vue'; // Example of a form component for a specific type
  // Import other form components
  
  export default {
    components: {
      EditClientProfile,
      // Register other form components here
    },
    data() {
      return {
        clientDetails: "",
      };
    },
    computed: {
      formComponent() {
        return 'EditClientProfile';
      },
    },
    async created() {
      await this.fetchProfileDetails();
    },
    methods: {
      async fetchProfileDetails() {
        const client_id = this.$route.params.client_id;
        console.log(client_id)
        try {
          const apiDirectory = process.env.API_DIRECTORY;
          const response = await axios.get(`${apiDirectory}/clients/` + client_id + "/");
          this.clientDetails = response.data;
        } catch (error) {
          console.error("Failed to fetch employee details:", error);
          // Handle error (e.g., show user feedback)
        }
      }
    }
  };
  </script>