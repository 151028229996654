<template>
  <div class="nav-bar-operations">
      <img src="@/assets/logogruas.png" alt="HomePage" @click="backMainPanel" class="logo-button"/>
  </div>
  <div class="text-status-units">
      <h2 class="order-type">Estatus de unidades</h2>
  </div>
  <div class="table-and-map-container">
      <!-- Table section -->
      <div class="table-map-section">
          <table class="table">
              <thead>
                  <tr>
                      <th>Estatus</th>
                      <th>ID unidad</th>
                      <th>ID nota en transcurso</th>
                      <th>ID notas en cola</th>
                      <th>Operador</th>
                      <th>Habilitada</th>
                  </tr>
              </thead>
              <tbody>
                <tr v-for="(location, index) in paginatedOrders" :key="index" @click="selectUnit(location)">
                  <td>
                    <span v-if="location.unit_status === 1" style="background-color: green; color: white; padding: 2px 5px; border-radius: 3px;">Disponible</span>
                    <span v-else-if="location.unit_status === 2" style="background-color: orange; color: white; padding: 2px 5px; border-radius: 3px;">Ocupada</span>
                    <span v-else-if="location.unit_status === 3" style="background-color: red; color: white; padding: 2px 5px; border-radius: 3px;">Fuera de servicio</span>
                    <span v-else-if="location.unit_status === 4" style="background-color: yellow; color: black; padding: 2px 5px; border-radius: 3px;">En servicio</span>
                    <span v-else>{{ location.unit_status }}</span>
                  </td>
                  <td>{{ location.vehicle_name }}</td>
                  <td>{{ location.assignations && location.assignations.length > 0 ? location.assignations[0].assistance_note.note_ident : '' }}</td>
                  <td>{{ location.assignations ? location.assignations.filter(a => !a.end_time).map(a => a.assistance_note.note_ident).join(', ') : '' }}</td>
                  <td>{{ location.assignations && location.assignations.length > 0 ? location.assignations[0].operator.id + ' - ' + location.assignations[0].operator.employee_first_name : '' }}</td>
                  <td>
                    <div :class="{'grayed-out-unit-in-maintenance':location.unit_status==5}">
                    <input type="checkbox" v-model="location.unit_enabled" :checked="isUnitEnabled(location)" @change="toggleUnit(location)" :disabled="location.unit_status==5"> 
                    </div>
                  </td>
                </tr>
              </tbody>
          </table>
          <div class="pagination-container">
              <button @click="prevPage" :disabled="currentPage === 1"> 
                  <img src="@/assets/angulo-izquierdo.png" alt="Previous Page" />
              </button>
              <span>Página {{ currentPage }} de {{ totalPages }}</span>
              <button @click="nextPage" :disabled="currentPage >= totalPages">
                  <img src="@/assets/angulo-derecho.png" alt="Next Page" />
              </button>
          </div>
      </div>
      
      <div class="table-map-section">
        <Map
          :center="mapCenter"
          :zoom="8"
          map-type-id="roadmap"
          style="height: 60vh"
        >
          <Marker
            v-if="selectedLocation"
            :key="selectedLocation.vehicle_plate"
            :position="{ lat: parseFloat(selectedLocation.lat), lng: parseFloat(selectedLocation.long) }"
            :icon="require('@/assets/grua-map-icon-marker.png')"
            @click="openInfoWindow(selectedLocation)"
          />
          <InfoWindow
            :options="{ content: infoContent }"
            :position="infoPosition"
            :opened="infoWindowOpened"
            @closeclick="infoWindowOpened = false"
          />
        </Map>
      </div>
  </div>
</template>

<style>
.nav-bar-operations {
  position: absolute; 
  top: 0;
  left: 0;
  width: 100vw; 
  height: 10vh; 
  background-color: #FFC434;
  z-index: 1000; 
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  margin: 0;
}
.text-status-units{
  display: flex;
  justify-content: center;
  margin-top: 11vh;
}
.table {
  width: 100%;
  border-collapse: collapse;
}
.table th, .table td {
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
}
.table th, .table td {
  border-left: none; 
  border-right: none; 
  padding: 8px;
  text-align: left;
  font-size: 0.9em;
}
.table tr {
  border-left: none; 
  border-right: none; 
}
.table tbody tr:first-child td {
  border-top: none;
}
.table tbody tr:hover td{
  background-color: #bdbdbd;
  color: rgb(0, 0, 0);
}
.table tbody tr:last-child td {
  border-bottom: none;
}
.pagination-container {
  justify-content: center;
  align-items: center;
}
.pagination-container button {
  padding: 5px 10px;
  border: 1px solid #000;
  background-color: #fff;
  cursor: pointer;
  margin: 0 10px;
  background-color: transparent; 
  border: none; 
}
.pagination-container button:disabled {
  color: #ccc;
  cursor: not-allowed;
}
.pagination-container span {
  font-size: 16px;
}
.pagination-container button img {
  width: 25px; 
  height: auto; 
}
.table-and-map-container {
  display: flex;
  justify-content: space-between;
}
.table-map-section {
  flex-basis: calc(50% - 10px); /* Ajuste para margen entre secciones */
  margin-right: 10px; /* Margen entre secciones */
  background: #fff; /* Fondo para cada sección del formulario */
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1); /* Sombra suave para resaltar secciones */
}
.table-map-section:last-child {
  margin-right: 0; /* Para que no haya margen a la derecha de la última sección */
}
.grayed-out-unit-in-maintenance {
  position: relative;
  opacity: 0.7;
  cursor: not-allowed;
}
.grayed-out-unit-in-maintenance::after {
  content: "Esa unidad se encuentra en mantenimiento. Para habilitarla, primero se debe cerrar la nota de mantenimiento";
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  white-space: normal;
  max-width: 300px;
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}
.grayed-out-unit-in-maintenance:hover::after {
    opacity: 1; /* Show the text on hover */
}
</style>

<script>
import { ref, reactive, onMounted, nextTick } from 'vue'
import { Map, Marker, InfoWindow } from '@fawmi/vue-google-maps'
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  components: {
    Map,
    Marker,
    InfoWindow
  },
  data() {
    const locations = ref([])
    const mapCenter = ref({ lat: 25.66831199290133, lng: -100.2802354639708 })
    const infoWindowOpened = ref(false);
    const infoContent = ref('');
    const infoPosition = reactive({ lat: 0, lng: 0 });
    const selectedLocation = ref(null);

    const openInfoWindow = (location) => {
      infoWindowOpened.value = false;
      infoContent.value = `
        <div>
          <h1>${location.vehicle_name}</h1>
          <p>Placas: ${location.vehicle_plate}</p>
          <p>Velocidad Actual: ${location.vehicle_speed} km/h</p>
        </div>
      `;
      infoPosition.lat = parseFloat(location.lat);
      infoPosition.lng = parseFloat(location.long);
      nextTick(() => {
        infoWindowOpened.value = true;
      });
    };

    const fetchLocationsAndAssignations = () => {
      const apiDirectory = process.env.API_DIRECTORY;
      axios.get(`${apiDirectory}/get-tows-status`)
        .then(response => {
          locations.value = response.data;
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }

    const selectUnit = (location) => {
      selectedLocation.value = location;
    };

    onMounted(() => {
      fetchLocationsAndAssignations();
      setInterval(() => {
        fetchLocationsAndAssignations();
      }, 20000);
    });

    return {
      locations,
      mapCenter,
      infoWindowOpened,
      infoContent,
      infoPosition,
      selectedLocation,
      openInfoWindow,
      selectUnit,
      currentPage: 1,
      pageSize: 30
    };
  },
  computed: {
    paginatedOrders() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.locations.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.locations.length / this.pageSize);
    }
  },
  methods: {
    backMainPanel() {
      window.location.href = '/main-panel';
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    async toggleUnit(location){
    /**Handles the enabling/disabling of a unit */
    const apiDirectory = process.env.API_DIRECTORY;
    try{
      const enabled = location.unit_enabled == true ? 1 :0;
      await axios.post(`${apiDirectory}/enable-disable-unit`,{"unit_short_id":location.unit_short_id,"enabled":enabled})
      if (location.unit_enabled){
        Swal.fire('Unidad habilitada',`Se ha habilitado la unidad: ${location.unit_short_id}`,'success')
      }
      else{
        Swal.fire('Unidad deshabilitada',`Se ha deshabilitado la unidad: ${location.unit_short_id}`,'success')
      }
    }
    catch(error){
      console.error(error)
      // revert status due to failure 
      location.unit_enabled = !location.unit_enabled
      if (error.response.status == 500){
        Swal.fire('¡Error!',"Error interno de servidor, por favor intentelo de nuevo. Si el problema persiste, contacte a su administrador",'error')
      }
      if (error.response.status == 400){
        Swal.fire('¡Error!',"Hubo un error con la solicitud, por favor intentelo de nuevo. Si el problema persiste, contacte a su administrador",'error')
      }
      return
    }
  },
  isUnitEnabled(location){
    /**Checks wether a unit is in a status that can be considered enabled */
    return location.unit_status!=5 & location.unit_status!=3;
  }
  }
};
</script>
