<template>
    <div class="nav-bar-new-order">
        <div class="logo-button">
            <img src="@/assets/logogruas.png" alt="HomePage" @click="navegar" />
        </div>
    </div>
    <div class="text-operations">
        <h2>Crear nueva orden</h2>
    </div>
    <h2 class="order-type">Tipo de orden: {{ selectedOption }}</h2> <!-- Mostrar la opción seleccionada aquí -->
    <div class="dropdown">
        <b-dropdown id="dropdown-1" text="Selecciona una opción" class="m-md-2">
            <b-dropdown-item @click="selectOption('Asistencia')">Asistencia</b-dropdown-item>
            <b-dropdown-item @click="selectOption('Siniestro')">Siniestro</b-dropdown-item>
            <b-dropdown-item @click="selectOption('Montacarga')">Montacarga</b-dropdown-item>
            <b-dropdown-item @click="selectOption('Lote')">Lote</b-dropdown-item>
        </b-dropdown>
    </div>
    
    <div v-if="selectedOption === 'Asistencia'">
        <div class="form-container">
            <!-- Datos del cliente -->
            <div class="form-section">
                <h3>Datos del cliente</h3>
                <img src="@/assets/client-icon.png">
                <!-- Formulario de datos del cliente -->
                <!-- ...campos de cliente... -->
                <div class="form-group">
                    <label for="client_name">Cliente</label>
                    <input type="text" id="client_name" placeholder="Nombre del cliente" />
                </div>
                <div class="form-group">
                    <label for="client_street">Calle</label>
                    <input type="text" id="client_street" placeholder="Ej. Av. Eugenio Garza Sada" />
                </div>
                <!--
                <div class="form-group">
                    <label for="client_codigoPostal">C.P. (Código Postal)</label>
                    <input type="text" id="client_codigoPostal" placeholder="Ej. 64849" />
                </div>
                -->
                <div class="form-group">
                    <label for="client_neighborhood">Colonia</label>
                    <input type="text" id="client_neighborhood" placeholder="Ej. Tecnológico" />
                </div>
                <div class="form-group">
                    <label for="client_city">Ciudad</label>
                    <input type="text" id="client_city" placeholder="Ingrese una ciudad" />
                </div>
                <div class="form-group">
                    <label for="client_state">Estado</label>
                    <input type="text" id="client_state" placeholder="Ingrese un estado" />
                </div>
                <div class="form-group">
                    <label for="client_rfc">RFC</label>
                    <input type="text" id="client_rfc" placeholder="Ingrese el RFC" />
                </div>
                <!--<div class="form-group">
                    <label for="telefono">Teléfono??</label>
                    <input type="tel" id="telefono" placeholder="Ej. 5652789143" />
                </div>
                -->
            </div>

            <!-- Datos del vehículo -->
            <div class="form-section">
                <h3>Datos del vehículo</h3>
                <img src="@/assets/vehicle-icon.png">
                <!-- Formulario de datos del vehículo -->
                <!-- ...campos de vehículo... -->
                <div class="form-group">
                    <label for="vehicle_brand">Marca</label>
                    <input type="text" id="vehicle_brand" placeholder="Ingrese una marca" />
                </div>
                <div class="form-group">
                    <label for="vehicle_subbrand">Submarca</label>
                    <input type="text" id="vehicle_subbrand" placeholder="Seleccione un modelo" />
                </div>
                <div class="form-group">
                    <label for="vehicle_model">Modelo</label>
                    <input type="text" id="vehicle_model" placeholder="Ingrese un año" />
                </div>
                <div class="form-group">
                    <label for="vehicle_plates">Placas</label>
                    <input type="text" id="vehicle_plates" placeholder="Ingrese las placas" />
                </div>
                <div class="form-group">
                    <label for="vehicle_engine_series">Serie del motor</label>
                    <input type="text" id="vehicle_engine_series" placeholder="Ingrese la serie del motor" />
                </div>
                <div class="form-group">
                    <label for="vehicle_color">Color</label>
                    <input type="text" id="vehicle_color" placeholder="Ingrese el color" />
                </div>
                <div class="form-group">
                    <label for="insurance_policy">Póliza</label>
                    <input type="text" id="insurance_policy" placeholder="Ingrese la póliza" />
                </div>
                <div class="form-group">
                    <label for="vehicle_owner_name">Dueño (Asegurado)</label>
                    <input type="tel" id="vehicle_owner_name" placeholder="Ingrese el nombre del asegurado" />
                </div>
            </div>

            <!-- Datos de ubicación -->
            <div class="form-section">
                <h3>Ubicación de Origen</h3>
                <img src="@/assets/ubi-icon.png">
                <!-- Formulario de datos de ubicación -->
                <!-- ...campos de ubicación... -->
                <div class="form-group">
                    <label for="source_street">Calle</label>
                    <input type="text" id="source_street" placeholder="Ingrese la calle principal" />
                </div>
                <div class="form-group">
                    <label for="source_inbtw">Calles Adicionales</label>
                    <input type="text" id="source_inbtw" placeholder="Ingrese entre que calles se encuentra" />
                </div>
                <div class="form-group">
                    <label for="source_neighborhood">Colonia</label>
                    <input type="text" id="source_neighborhood" placeholder="Ingrese la colonia" />
                </div>
                <div class="form-group">
                    <label for="source_number">Número</label>
                    <input type="text" id="source_number" placeholder="Ingrese el número" />
                </div>
                <div class="form-group">
                    <label for="source_municipality">Municipio</label>
                    <input type="text" id="source_municipality" placeholder="Ingrese el municipio" />
                </div>
                <div class="form-group">
                    <label for="source_postal_code">C.P (Código Postal)</label>
                    <input type="text" id="source_postal_code" placeholder="Ingrese el código postal" />
                </div>
                <div class="form-group">
                    <label for="source_state">Estado</label>
                    <input type="tel" id="source_state" placeholder="Ingrese el estado" />
                </div>
                <div class="form-group">
                    <label for="source_lat">Latitud</label>
                    <input type="tel" id="source_lat" placeholder="Ingrese la latitud" />
                </div>
                <div class="form-group">
                    <label for="source_long">Longitud</label>
                    <input type="tel" id="source_long" placeholder="Ingrese la longitud" />
                </div>
                <div class="form-group">
                    <label for="source_km">KM (Solo si es en carretera)</label>
                    <input type="tel" id="source_km" placeholder="Ingrese el KM de la carretera" />
                </div>
                <div class="form-group">
                    <label for="source_notes">Notas</label>
                    <input type="tel" id="source_notes" placeholder="Ingrese información adicional" />
                </div>
                
            </div>

            <div class="form-section">
                <h3>Ubicación de Destino</h3>
                <img src="@/assets/ubi-icon.png">
                <!-- Formulario de datos de ubicación -->
                <!-- ...campos de ubicación... -->
                <div class="form-group">
                    <label for="destination_street">Calle</label>
                    <input type="text" id="destination_street" placeholder="Ingrese la calle principal" />
                </div>
                <div class="form-group">
                    <label for="destination_inbtw">Calles Adicionales</label>
                    <input type="text" id="destination_inbtw" placeholder="Ingrese entre que calles se encuentra" />
                </div>
                <div class="form-group">
                    <label for="destination_neighborhood">Colonia</label>
                    <input type="text" id="destination_neighborhood" placeholder="Ingrese la colonia" />
                </div>
                <div class="form-group">
                    <label for="destination_number">Número</label>
                    <input type="text" id="destination_number" placeholder="Ingrese el número" />
                </div>
                <div class="form-group">
                    <label for="destination_municipality">Municipio</label>
                    <input type="text" id="destination_municipality" placeholder="Ingrese el municipio" />
                </div>
                <div class="form-group">
                    <label for="destination_postal_code">C.P (Código Postal)</label>
                    <input type="text" id="destination_postal_code" placeholder="Ingrese el código postal" />
                </div>
                <div class="form-group">
                    <label for="destination_state">Estado</label>
                    <input type="tel" id="destination_state" placeholder="Ingrese el estado" />
                </div>
                <div class="form-group">
                    <label for="destination_lat">Latitud</label>
                    <input type="tel" id="destination_lat" placeholder="Ingrese la latitud" />
                </div>
                <div class="form-group">
                    <label for="destination_long">Longitud</label>
                    <input type="tel" id="destination_long" placeholder="Ingrese la longitud" />
                </div>
                <div class="form-group">
                    <label for="destination_km">KM (Solo si es en carretera)</label>
                    <input type="tel" id="destination_km" placeholder="Ingrese el KM de la carretera" />
                </div>
                <div class="form-group">
                    <label for="destination_notes">Notas</label>
                    <input type="tel" id="destination_notes" placeholder="Ingrese información adicional" />
                </div>
            </div>
        </div>
        <button type="submit" class="btn-submit-save">Guardar</button>
        <button type="submit" class="btn-submit-cancel">Cancelar</button>
    </div>

    <div v-if="selectedOption === 'Siniestro'">
      <!-- Formulario para Siniestro -->
      <form @submit.prevent="enviarFormulario">
        <!-- Campos específicos para Siniestro -->
        <input type="text" placeholder="Ubicación del siniestro" />
        <input type="text" placeholder="Tipo de siniestro" />
        <!-- Más campos según sea necesario -->
        <button type="submit">Enviar</button>
      </form>
    </div>

    <div v-if="selectedOption === 'Montacarga'">
      <!-- Formulario para Montacarga -->
      <form @submit.prevent="enviarFormulario">
        <!-- Campos específicos para Montacarga -->
        <input type="number" placeholder="Peso a levantar" />
        <input type="text" placeholder="Ubicación del servicio" />
        <!-- Más campos según sea necesario -->
        <button type="submit">Enviar</button>
      </form>
    </div>

    <div v-if="selectedOption === 'Lote'">
      <!-- Formulario para Lote -->
      <form @submit.prevent="enviarFormulario">
        <!-- Campos específicos para Lote -->
        <input type="text" placeholder="Número de lote" />
        <input type="text" placeholder="Ubicación del lote" />
        <!-- Más campos según sea necesario -->
        <button type="submit">Enviar</button>
      </form>
    </div>
</template>

<style scoped>
.nav-bar-new-order{
    position: absolute;
    width: 100vw;
    height: 10vh;
    top: 0;
    left: 0;
    background-color:#FFC434;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
    cursor: pointer;
}
.text-operations{
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    margin-top: 100px;
    height: 4vh;
    border-bottom: 1px solid black;
    margin-left: 20px;
    margin-right: 20px;
}
.order-type{
    display: flex;
    margin-top: 3vh;
    margin-left: 20px;
}
.dropdown {
    position: relative;
    display: flex;
    margin-left: 13px;
}

.form-group {
    margin-bottom: 15px;
    width: 10vw;
}

label {
    display: block;
    margin-bottom: 10px;
}

input[type="text"],
input[type="tel"] {
    width: 25vw;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.btn-submit-save {
    background-color: #00ff1e; /* Color del botón de enviar */
    color: #fff; /* Color del texto del botón */
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}
.btn-submit-cancel {
    background-color: #ff0000; /* Color del botón de enviar */
    color: #fff; /* Color del texto del botón */
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}
.form-container {
    display: flex;
    justify-content: space-between;
    margin: 10px;
  }

  .form-section {
    flex-basis: calc(33% - 10px); /* Ajuste para margen entre secciones */
    margin-right: 20px; /* Margen entre secciones */
    background: #fff; /* Fondo para cada sección del formulario */
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1); /* Sombra suave para resaltar secciones */
  }

  .form-section:last-child {
    margin-right: 0; /* Para que no haya margen a la derecha de la última sección */
  }

h2 {
    margin-bottom: 20px; /* Espacio debajo de los títulos de sección */
}
.form-group label {
  text-align: left; /* Alinea el texto de la etiqueta a la izquierda */
  font-weight: bold; /* Hace que el texto de la etiqueta sea negrita */
  display: inline-block; /* Hace que la etiqueta sea en bloque pero alinee su contenido */
  width: 98%; /* Asegura que la etiqueta ocupe todo el ancho disponible */
}
.form-section img{
    width: 40px;
    height: 40px;
}
</style>

<script>
export default{
    data() {
        return {
            selectedOption: ''
        };
    },
    methods: {
        navegar() {
            window.location.href = './main-panel';
        },
        selectOption(option) {
            this.selectedOption = option;
        },
        enviarFormulario() {
        // Lógica para manejar la presentación del formulario
        },
    }
}
</script>