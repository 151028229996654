<template>
    <div class="nav-bar-maintenance">
        <img src="@/assets/logogruas.png" alt="HomePage" @click="backMainPanel" class="logo-button"/>
    </div>
    <div class="action-buttons-maintenance">
       <!-- <button @click="addUnit" class="action-button-maintenance">Agregar nuevo cliente</button> -->
       <button @click="groupedBilling" class="action-button-maintenance">Facturación agrupada</button>
    </div>


    <div class="table-container-maintenance">
        <h4>Tabla de clientes</h4>
        <table class="table">
            <thead>
                <tr>
                    <th>Numero de Cliente<input v-model="filters.client_ident" @input="applyFiltersUnit" placeholder="Buscar por numero de cliente" /></th>
                    <th>Nombre<input v-model="filters.client_name" @input="applyFiltersUnit" placeholder="Buscar por nombre" /></th>
                    <th>Teléfono<input v-model="filters.phone" @input="applyFiltersUnit" placeholder="Buscar por telefono" /></th>
                    <th>Dirección<input v-model="filters.client_address" @input="applyFiltersUnit" placeholder="Buscar por direccion" /></th>
                    <th>RFC<input v-model="filters.client_rfc" @input="applyFiltersUnit" placeholder="Buscar por rfc" /></th>
                    <th>Estatus<input v-model="filters.client_status" @input="applyFiltersUnit" placeholder="Buscar por estatus" /></th>
                    <th>Tipo<input v-model="filters.is_privileged" @input="applyFiltersUnit" placeholder="Buscar por tipo de cliente" /></th>
                    <th>Régimen fiscal<input v-model="filters.tax_regime" @input="applyFiltersUnit" placeholder="Buscar por regimen fiscal" /></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(client, index) in paginatedClients" :key="index"
                    @click="viewClientDetails(client.client_name, client.id)">
                    <td><span style="background-color: #FFC434; color: black; font-weight: bold; padding: 2px 5px; border-radius: 3px;">{{client.client_ident }}</span></td>
                    <td>{{ client.client_name }}</td>
                    <td>{{ client.phone }}</td>
                    <td>{{ client.client_address.city }}, {{ client.client_address.state }}</td>
                    <td>{{ client.client_rfc }}</td>
                    <td>
                        <span v-if="client.client_status === 'Activo'" style="background-color: green; color: white; padding: 2px 5px; border-radius: 3px;">Activo</span>
                        <span v-else-if="client.client_status === 'Inactivo'" style="background-color: red; color: white; padding: 2px 5px; border-radius: 3px;">Inactivo</span>
                        <span v-else>{{ client.client_status }}</span>
                    </td>
                    <td>
                        <span v-if="client.is_privileged === true" style="background-color: purple; color: white; padding: 2px 5px; border-radius: 3px;">Regular</span>
                        <span v-else-if="client.is_privileged === false" style="background-color: blue; color: white; padding: 2px 5px; border-radius: 3px;">Particular</span>
                        <span v-else>{{ client.is_privileged }}</span>
                    </td>
                    <td>{{ client.tax_regime }}</td>
                </tr>
            </tbody>
        </table>
        <div class="pagination-container">
            <button @click="prevPage" :disabled="currentPage === 1">
                <img src="@/assets/angulo-izquierdo.png" alt="Previous Page" />
            </button>
            <span v-for="pageNumber in totalClientsPages" :key="pageNumber" @click="goToPage(pageNumber)"
                :class="{ active: pageNumber === currentPage }">{{ pageNumber }}</span>
            <button @click="nextPage" :disabled="currentPage >= totalClientsPages">
                <img src="@/assets/angulo-derecho.png" alt="Next Page" />
            </button>
        </div>
    </div>
</template>

<style>
.nav-bar-maintenance {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10vh;
    background-color: #FFC434;
    z-index: 1000;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
}
.logo-button {
    position: absolute;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    margin: 0;
}
.table-container-maintenance {
    flex-grow: 1;
    overflow-x: auto;
    margin-top: 1vh;
    padding: 1vh;
}
.table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.6em; 
}
.table th, .table td {
    border-left: none;
    border-right: none;
    padding: 5px; 
    text-align: left;
}
.table tbody tr:first-child td {
    border-top: none;
}
.table tbody tr:hover td {
    background-color: #bdbdbd;
    color: rgb(0, 0, 0);
}
.table tbody tr:last-child td {
    border-bottom: none;
}
.table thead input {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    margin: 0;
    border-radius: 5px;
    border-width: 1px;
}
.table th{
    border-left: none;
    border-right: none;
    padding: 10px;
    text-align: left
}
.table td {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}
.action-buttons-maintenance {
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    align-items: center;
    margin-top: 13vh; 
    height: 3vh; 
}
.action-button-maintenance {
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color: #FFC434;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
    margin: 0 10px; 
}
.action-button-maintenance:hover {
    background-color: #dba907;
}
@media (max-width: 768px) {
    .action-buttons {
        flex-direction: column;
        align-items: stretch;
    }
}
@media screen and (min-width: 768px) {
    .table th, .table td {
        width: 8%; 
    }
}
</style>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            currentPage: 1,
            pageSize: 12,
            clients : [],
            n_clients: 0,
            filters: {
                client_ident: '',
                client_name: '',
                phone: '',
                client_address: '',
                client_rfc: '',
                client_status: '',
                is_privileged: '',
                tax_regime: ''
            },
            originalClients: []
        }
    },
    computed: {
        paginatedClients() {
            return this.clients
        },
        totalClientsPages() {
            return Math.ceil(this.n_clients / this.pageSize);
        }
    },
    mounted() {
        this.fetchClients();
    },
    methods: {
        backMainPanel() {
            window.location.href = '/main-panel';
        },
        addUnit() {
            window.location.href = '/agregar-nueva-unidad';
        },
        groupedBilling() {
            window.location.href = '/facturar-agrupadas';
        },
        goToPage(pageNumber) {
            this.currentPage = pageNumber;
            const page = this.currentPage
            this.fetchClients(page);
        },
        async fetchClients(page = 1) {
            /**Fetches the in process notes using the provided filters*/
            let response
            const apiDirectory = process.env.API_DIRECTORY;

            try {
                response = await axios.post(`${apiDirectory}/get-client?page=${page}&page_size=${this.pageSize}`,this.filters)
                const responseData = response.data.results;
                this.clients = responseData
                this.n_clients = response.data.count
                this.originalClients = [...responseData]
            }
            catch (error) {
                console.error('Error fetching data from API:', error)
            }
        },
        nextPage() {
            if (this.currentPage < this.totalClientsPages) this.currentPage++;
            const page = this.currentPage
            this.fetchClients(page);
        },
        prevPage() {
            if (this.currentPage > 1) this.currentPage--;
            const page = this.currentPage
            this.fetchClients(page);
        },
        applyFiltersUnit() {
            this.fetchClients()
        },
        viewClientDetails(client_name, client_id) {
            Swal.fire({
                title: '¿Estás seguro?',
                text: `¿Quieres ver los detalles del cliente ${client_name}?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, ver detalles',
                cancelButtonText: 'No, cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$router.push({ name: 'EditClient', params: { client_id } });
                }
            });
        },
    }
}
</script>