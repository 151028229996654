<template>
    <div class="nav-bar-operations">
        <img src="@/assets/logogruas.png" alt="HomePage" @click="backMainPanel" class="logo-button"/>
    </div>
    <div class="text-status-units">
        <h2 class="order-type">Estatus de unidades</h2>
    </div>
    <div class="table-and-map-container">
        <!-- Table section -->
        <div class="table-map-section">
            <table class="table">
                <thead>
                    <tr>
                        <th>Estatus</th>
                        <th>ID unidad</th>
                        <th>ID nota en transcurso</th>
                        <th>ID notas en cola</th>
                        <th>Operador</th>
                    </tr>
                </thead>
                <tbody>
                  <tr v-for="location in paginatedOrders" :key="location.vehicle_plate" @click="selectUnit(location)">
                    <td>{{ location.unit_status }}</td>
                    <td>{{ location.vehicle_name }}</td>
                    <td>{{ location.assignation ? location.assignation.assistance_note.id : '' }}</td>
                    <td>{{ location.assignation ? location.assignation.queue_notes.map(note => note.id).join(', ') : '' }}</td>
                    <td>{{ location.operator_employee_name }}</td>
                  </tr>
                </tbody>
            </table>
            <div class="pagination-container">
                <button @click="prevPage" :disabled="currentPage === 1"> 
                    <img src="@/assets/angulo-izquierdo.png" alt="Previous Page" />
                </button>
                <span>Página {{ currentPage }} de {{ totalPages }}</span>
                <button @click="nextPage" :disabled="currentPage >= totalPages">
                    <img src="@/assets/angulo-derecho.png" alt="Next Page" />
                </button>
            </div>
        </div>
        
        <div class="table-map-section">
          <Map
            :center="mapCenter"
            :zoom="8"
            map-type-id="roadmap"
            style="height: 60vh"
          >
            <Marker
              v-if="selectedLocation"
              :key="selectedLocation.vehicle_plate"
              :position="{ lat: parseFloat(selectedLocation.lat), lng: parseFloat(selectedLocation.long) }"
              :icon="require('@/assets/grua-map-icon-marker.png')"
              @click="openInfoWindow(selectedLocation)"
            />
            <InfoWindow
              :options="{ content: infoContent }"
              :position="infoPosition"
              :opened="infoWindowOpened"
              @closeclick="infoWindowOpened = false"
            />
          </Map>
        </div>
    </div>
  </template>
  
  <style>
  .nav-bar-operations {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100vw; 
    height: 10vh; 
    background-color: #FFC434;
    z-index: 1000; 
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
  }
  .text-status-units{
    display: flex;
    justify-content: center;
    margin-top: 11vh;
  }
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  .table th, .table td {
    border: 1px solid #000;
    padding: 8px;
    text-align: left;
  }
  .table th, .table td {
    border-left: none; 
    border-right: none; 
    padding: 8px;
    text-align: left;
    font-size: 0.9em;
  }
  .table tr {
    border-left: none; 
    border-right: none; 
  }
  .table tbody tr:first-child td {
    border-top: none;
  }
  .table tbody tr:hover td{
    background-color: #bdbdbd;
    color: rgb(0, 0, 0);
  }
  .table tbody tr:last-child td {
    border-bottom: none;
  }
  .pagination-container {
    justify-content: center;
    align-items: center;
  }
  .pagination-container button {
    padding: 5px 10px;
    border: 1px solid #000;
    background-color: #fff;
    cursor: pointer;
    margin: 0 10px;
    background-color: transparent; 
    border: none; 
  }
  .pagination-container button:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
  .pagination-container span {
    font-size: 16px;
  }
  .pagination-container button img {
    width: 25px; 
    height: auto; 
  }
  .table-and-map-container {
    display: flex;
    justify-content: space-between;
  }
  .table-map-section {
    flex-basis: calc(50% - 10px); /* Ajuste para margen entre secciones */
    margin-right: 10px; /* Margen entre secciones */
    background: #fff; /* Fondo para cada sección del formulario */
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1); /* Sombra suave para resaltar secciones */
  }
  .table-map-section:last-child {
    margin-right: 0; /* Para que no haya margen a la derecha de la última sección */
  }
  </style>
  
  <script>
  import { ref, reactive, onMounted, nextTick } from 'vue'
  import { Map, Marker, InfoWindow } from '@fawmi/vue-google-maps'
  import axios from 'axios';
  
  export default {
    components: {
      Map,
      Marker,
      InfoWindow
    },
    data() {
      const locations = ref([])
      const mapCenter = ref({ lat: 25.66831199290133, lng: -100.2802354639708 })
      const infoWindowOpened = ref(false);
      const infoContent = ref('');
      const infoPosition = reactive({ lat: 0, lng: 0 });
      const selectedLocation = ref(null);
  
      const openInfoWindow = (location) => {
        infoWindowOpened.value = false;
        infoContent.value = `
          <div>
            <h1>${location.vehicle_name}</h1>
            <p>Placas: ${location.vehicle_plate}</p>
            <p>Velocidad Actual: ${location.vehicle_speed} km/h</p>
          </div>
        `;
        infoPosition.lat = parseFloat(location.lat);
        infoPosition.lng = parseFloat(location.long);
        nextTick(() => {
          infoWindowOpened.value = true;
        });
      };
  
      const fetchLocationsAndAssignations = () => {
        const apiDirectory = process.env.API_DIRECTORY;
        axios.get(`${apiDirectory}/get-tows-status`)
          .then(response => {
            locations.value = response.data;
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }
  
      const selectUnit = (location) => {
        selectedLocation.value = location;
      };
  
      onMounted(() => {
        fetchLocationsAndAssignations();
        setInterval(() => {
          fetchLocationsAndAssignations();
        }, 20000);
      });
  
      return {
        locations,
        mapCenter,
        infoWindowOpened,
        infoContent,
        infoPosition,
        selectedLocation,
        openInfoWindow,
        selectUnit,
        currentPage: 1,
        pageSize: 15
      };
    },
    computed: {
      paginatedOrders() {
        const start = (this.currentPage - 1) * this.pageSize;
        const end = start + this.pageSize;
        return this.locations.slice(start, end);
      },
      totalPages() {
        return Math.ceil(this.locations.length / this.pageSize);
      }
    },
    methods: {
      backMainPanel() {
        window.location.href = '/main-panel';
      },
      nextPage() {
        if (this.currentPage < this.totalPages) this.currentPage++;
      },
      prevPage() {
        if (this.currentPage > 1) this.currentPage--;
      }
    }
  };
  </script>