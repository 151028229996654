<template>
    <div class="nav-bar-maintenance">
        <img src="@/assets/logogruas.png" alt="HomePage" @click="backMainPanel" class="logo-button"/>
    </div>
    
    <div class="void-new-unit"></div>

    <div class="form-section">
        <h4>Datos del personal</h4>
        <img src="@/assets/client-icon.png">
    </div>
    <div class="form-container">
        <div class="form-section">
            <div class="form-group">
                <label for="????">ID</label>
                <input type="text" id="???" placeholder="Introduce el ID" v-model="employee_ident"/> <!--Añadir v-model=""-->
            </div>
            <div class="form-group">
                <label for="????">Nombre(s)</label>
                <input type="text" id="???" placeholder="Introduce el nombre" v-model="employee_first_name"/> <!--Añadir v-model=""-->
            </div>
            <div class="form-group">
                <label for="????">Primer apellido</label>
                <input type="text" id="???" placeholder="Introduce el primer apellido" v-model="employee_last_name"/> <!--Añadir v-model=""-->
            </div>
            <div class="form-group">
                <label for="????">Segundo apellido</label>
                <input type="text" id="???" placeholder="Introduce el segundo apellido" v-model="employee_last_name2"/> <!--Añadir v-model=""-->
            </div>
            <div class="form-group">
                <label for="????">Posición</label>
                <select id="???" v-model="employee_position">
                    <option value=1>Operador Grúa C-D</option>
                    <option value=2>Gerente Administrativo</option>
                    <option value=3>Tesoreria</option>
                    <option value=4>Rabon OP</option>
                    <option value=5>Administrativo</option>
                    <option value=6>Chofer Administrativo</option>
                    <option value=7>Sistemas</option>
                    <option value=8>Gerente General</option>
                    <option value=9>Jefe Recursos Humanos</option>
                    <option value=10>Credito y Cobranza</option>
                    <option value=11>Operador Grúa A-B</option>
                    <option value=12>Jefe Call Center</option>
                    <option value=13>Vulcanizador</option>
                    <option value=14>Operador de Montacargas</option>
                    <option value=15>Mécanico</option>
                    <option value=16>Ejecutivo Cuenta</option>
                </select> <!--Añadir v-model=""-->
            </div>
            <div class="form-group">
                <label for="????">Fecha de contratación</label>
                <input type="text" id="???" placeholder="Introduce la fecha de contratación" v-model="employee_hiring_date"/> <!--Añadir v-model=""-->
            </div>
            <div class="form-group">
                <label for="????">Departamento</label>
                <input type="text" id="???" placeholder="Introduce el departamento al que pertenece el personal" v-model="employee_department"/> <!--Añadir v-model=""-->
            </div>
        </div>
        <div class="form-section">
            <div class="form-group">
                <label for="????">Calle y número</label>
                <input type="text" id="???" placeholder="Introduce la calle del personal" v-model="employee_street"/> <!--Añadir v-model=""-->
            </div>
            <div class="form-group">
                <label for="????">Colonia</label>
                <input type="text" id="???" placeholder="Introduce la colonia" v-model="employee_neighborhood"/> <!--Añadir v-model=""-->
            </div>
            <div class="form-group">
                <label for="????">Municipio</label>
                <input type="text" id="???" placeholder="Introduce el municipio" v-model="employee_municipality"/> <!--Añadir v-model=""-->
            </div>
            <div class="form-group">
                <label for="????">Código postal</label>
                <input type="text" id="???" placeholder="Introduce el código postal" v-model="employee_postcode"/> <!--Añadir v-model=""-->
            </div>
            <div class="form-group">
                <label for="????">Número de celular</label>
                <input type="text" id="???" placeholder="Introduce el número de celular" v-model="employee_phone"/> <!--Añadir v-model=""-->
            </div>
            <div class="form-group">
                <label for="????">Sexo</label>
                <select id="???" v-model="employee_sex">
                    <option value=1>Masculino</option>
                    <option value=2>Femenino</option>
                </select> <!--Añadir v-model=""-->
            </div>
        </div>
        <div class="form-section">
            <div class="form-group">
                <label for="????">Fecha de nacimiento</label>
                <input type="text" id="???" placeholder="Introduce la fecha de nacimiento" v-model="employee_birthdate"/> <!--Añadir v-model=""-->
            </div>
            <div class="form-group">
                <label for="????">Estado civil</label>
                <input type="text" id="???" placeholder="Introduce el estado civil" v-model="employee_civil_state"/> <!--Añadir v-model=""-->
            </div>
            <div class="form-group">
                <label for="????">RFC</label>
                <input type="text" id="???" placeholder="Introduce el RFC" v-model="employee_rfc"/> <!--Añadir v-model=""-->
            </div>
            <div class="form-group">
                <label for="????">CURP</label>
                <input type="text" id="???" placeholder="Introduce el CURP" v-model="employee_curp"/> <!--Añadir v-model=""-->
            </div>
            <div class="form-group">
                <label for="????">Número del seguro Social</label>
                <input type="text" id="???" placeholder="Introduce el SSN" v-model="employee_ssn"/> <!--Añadir v-model=""-->
            </div>
            <div class="form-group">
                <label for="????">Número de licencia</label>
                <input type="text" id="???" placeholder="Introduce el SSN" v-model="employee_license_number"/> <!--Añadir v-model=""-->
            </div>
        </div>
    </div>

    <button type="submit" class="btn-submit-save" @click="saveEmployee">Guardar</button>
    <button type="button" class="btn-submit-cancel" onclick="history.back()">Cancelar</button>
</template>

<style>
.nav-bar-maintenance {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10vh;
    background-color: #FFC434;
    z-index: 1000;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
}
.logo-button {
    position: absolute;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    margin: 0;
}
.form-container {
    display: flex;
    justify-content: center;
    margin: 5px;
}
.form-section {
    flex-basis: calc(33% - 10px); 
    margin-right: 10px;
    background: #fff; 
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1); 
}
.form-section:last-child {
    margin-right: 0;
}
.form-group {
    margin-bottom: 15px;
    width: 5vw;
}
label {
    display: block;
    margin-bottom: 10px;
}
input[type="text"],
input[type="tel"] {
    width: 30vw;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.btn-submit-save {
    background-color: #00ff1e; 
    color: #fff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}
.btn-submit-cancel {
    background-color: #ff0000; 
    color: #fff; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}
h2 {
    margin-bottom: 20px;
}
.form-group label {
    text-align: left; 
    font-weight: bold; 
    display: inline-block; 
    width: 20vw; 
}
.form-section img{
    width: 40px;
    height: 40px;
}
.form-check-input {
    width: 20px;
}
textarea {
    width: 20vw; 
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    resize: vertical; 
}
.void-new-unit{
    margin-top: 10vh;
}
</style>

<script>

import axios from 'axios';
import Swal from 'sweetalert2'

export default {
    data(){
        return{
            employee_ident: '',
            employee_first_name: '',
            employee_last_name: '',
            employee_last_name2: '',
            employee_position: '',
            employee_hiring_date: '',
            employee_department: '',
            employee_street: '',
            employee_neighborhood: '',
            employee_municipality: '',
            employee_postcode: '',
            employee_phone: '',
            employee_sex: '',
            employee_birthdate: '',
            employee_civil_state: '',
            employee_rfc: '',
            employee_curp: '',
            employee_ssn: '',
            employee_status: 1,
            employee_license_number: ''
        }
    },
    methods: {
        backMainPanel() {
            window.location.href = '/main-panel';
        },

        async saveEmployee() {
            try {
                const apiDirectory = process.env.API_DIRECTORY;
                const employeeData = {
                    employee_ident: this.employee_ident,
                    employee_first_name: this.employee_first_name,
                    employee_last_name: this.employee_last_name,
                    employee_last_name2: this.employee_last_name2,
                    employee_position: this.employee_position,
                    employee_hiring_date: this.employee_hiring_date,
                    employee_department: this.employee_department,
                    employee_street: this.employee_street,
                    employee_neighborhood: this.employee_neighborhood,
                    employee_municipality: this.employee_municipality,
                    employee_postcode: this.employee_postcode,
                    employee_phone: this.employee_phone,
                    employee_sex: this.employee_sex,
                    employee_birthdate: this.employee_birthdate,
                    employee_civil_state: this.employee_civil_state,
                    employee_rfc: this.employee_rfc,
                    employee_curp: this.employee_curp,
                    employee_ssn: this.employee_ssn,
                    employee_status: this.employee_status,
                    employee_license_number: this.employee_license_number,
                };
                await axios.post(`${apiDirectory}/create-employee`, employeeData, {
                    headers:
                    {
                        'Content-Type': 'application/json',
                    },
                });
                Swal.fire(
                    '¡Guardado!',
                    'Los datos del formulario se han guardado con éxito.',
                    'success'
                ).then(() => {
                    this.$router.push({ name: 'RHModule' });
                });
            } catch (error) {
                Swal.fire('Error', 'Verifique que los datos que está ingresando son válidos', 'error');
            }
        }
    },
    computed: {
        isFormValid() {
            return (
                this.employee_ident !== "" &&
                this.employee_first_name !== "" &&
                this.employee_last_name !== "" &&
                this.employee_last_name2 !== "" &&
                this.employee_position !== "" &&
                this.employee_hiring_date !== "" &&
                this.employee_department !== "" &&
                this.employee_street !== "" &&
                this.employee_neighborhood !== "" &&
                this.employee_municipality !== "" &&
                this.employee_postcode !== "" &&
                this.employee_phone !== "" &&
                this.employee_sex !== "" &&
                this.employee_birthdate !== "" &&
                this.employee_civil_state !== "" &&
                this.employee_rfc !== "" &&
                this.employee_curp !== "" &&
                this.employee_ssn !== "" &&
                this.employee_status !== "" &&
                this.employee_license_number !== ""
            );
        }
    }
}
</script>