<template>
    <div class="nav-bar-maintenance">
        <img src="@/assets/logogruas.png" alt="HomePage" @click="backMainPanel" class="logo-button"/>
    </div>
    <div class="action-buttons-maintenance">
        <button @click="addUnit" class="action-button-maintenance">Agregar nueva unidad</button>
    </div>

    <div class="table-container-maintenance">
        <h4>Tabla de unidades</h4>
        <table class="table">
            <thead>
                <tr>
                    <th>ID<input v-model="filters.unit_id" @input="applyFiltersUnit" placeholder="Buscar por ID" /></th>
                    <th>Económico<input v-model="filters.unit_short_id" @input="applyFiltersUnit" placeholder="Buscar por # económico" /></th>
                    <th>Propietario<input v-model="filters.unit_owner" @input="applyFiltersUnit" placeholder="Buscar por propietario" /></th>
                    <th>Placas<input v-model="filters.unit_plates" @input="applyFiltersUnit" placeholder="Buscar por placas" /></th>
                    <th>Serie del motor<input v-model="filters.unit_engine_num" @input="applyFiltersUnit" placeholder="Buscar por serie de motor" /></th>
                    <th>Marca<input v-model="filters.unit_brand" @input="applyFiltersUnit" placeholder="Buscar por marca" /></th>
                    <th>SubMarca<input v-model="filters.unit_sub_brand" @input="applyFiltersUnit" placeholder="Buscar por submarca" /></th>
                    <th>Modelo<input v-model="filters.unit_model" @input="applyFiltersUnit" placeholder="Buscar por modelo" /></th>
                    <th>Clase<input v-model="filters.unit_class" @input="applyFiltersUnit" placeholder="Buscar por clase" /></th>
                    <th>Tipo<input v-model="filters.unit_type" @input="applyFiltersUnit" placeholder="Buscar por tipo" /></th>
                    <th>Categoría<input v-model="filters.unit_category" @input="applyFiltersUnit" placeholder="Buscar por categoría" /></th>
                    <th>Estatus<input v-model="filters.unit_status" @input="applyFiltersUnit" placeholder="Buscar por estatus" /></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(unit, index) in paginatedUnits" :key="index"
                    @click="viewUnitDetails(unit.unit_id)">
                    <td><span style="background-color: #FFC434; color: black; font-weight: bold; padding: 2px 5px; border-radius: 3px;">{{unit.unit_id }}</span></td>
                    <td>{{ unit.unit_short_id }}</td>
                    <td>{{ unit.unit_owner }}</td>
                    <td>{{ unit.unit_plates }}</td>
                    <td>{{ unit.unit_engine_num }}</td>
                    <td>{{ unit.unit_brand }}</td>
                    <td>{{ unit.unit_sub_brand }}</td>
                    <td>{{ unit.unit_model }}</td>
                    <td>{{ unit.unit_class }}</td>
                    <td>{{ unit.unit_type }}</td>
                    <td>{{ unit.unit_category }}</td>
                    <td>
                        <span v-if="unit.unit_status === 'Disponible'" style="background-color: green; color: white; padding: 2px 5px; border-radius: 3px;">Disponible</span>
                        <span v-else-if="unit.unit_status === 'En servicio'" style="background-color: red; color: white; padding: 2px 5px; border-radius: 3px;">En servicio</span>
                        <span v-else>{{ unit.unit_status }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="pagination-container">
            <button @click="prevPage" :disabled="currentPage === 1">
                <img src="@/assets/angulo-izquierdo.png" alt="Previous Page" />
            </button>
            <span v-for="pageNumber in totalUnitsPages" :key="pageNumber" @click="goToPage(pageNumber)"
                :class="{ active: pageNumber === currentPage }">{{ pageNumber }}</span>
            <button @click="nextPage" :disabled="currentPage >= totalUnitsPages">
                <img src="@/assets/angulo-derecho.png" alt="Next Page" />
            </button>
        </div>
    </div>
</template>

<style>
.nav-bar-maintenance {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10vh;
    background-color: #FFC434;
    z-index: 1000;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
}
.logo-button {
    position: absolute;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    margin: 0;
}
.table-container-maintenance {
    flex-grow: 1;
    overflow-x: auto;
    margin-top: 1vh;
    padding: 1vh;
}
.table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.6em; 
}
.table th, .table td {
    border-left: none;
    border-right: none;
    padding: 5px; 
    text-align: left;
}
.table tbody tr:first-child td {
    border-top: none;
}
.table tbody tr:hover td {
    background-color: #bdbdbd;
    color: rgb(0, 0, 0);
}
.table tbody tr:last-child td {
    border-bottom: none;
}
.table thead input {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    margin: 0;
    border-radius: 5px;
    border-width: 1px;
}
.table th{
    border-left: none;
    border-right: none;
    padding: 10px;
    text-align: left
}
.table td {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}
.action-buttons-maintenance {
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    align-items: center;
    margin-top: 13vh; 
    height: 3vh; 
}
.action-button-maintenance {
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color: #FFC434;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
    margin: 0 10px; 
}
.action-button-maintenance:hover {
    background-color: #dba907;
}
@media (max-width: 768px) {
    .action-buttons {
        flex-direction: column;
        align-items: stretch;
    }
}
@media screen and (min-width: 768px) {
    .table th, .table td {
        width: 8%; 
    }
}
</style>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            currentPage: 1,
            pageSize: 12,
            units : [],
            n_units: 0,
            filters: {
                unit_id: '',
                unit_short_id: '',
                unit_owner: '',
                unit_plates: '',
                unit_engine_num: '',
                unit_brand: '',
                unit_sub_brand: '',
                unit_model: '',
                unit_class: '',
                unit_type: '',
                unit_status: '',
                unit_category: ''
            },
            originalUnits: []
        }
    },
    computed: {
        paginatedUnits() {
            return this.units
        },
        totalUnitsPages() {
            return Math.ceil(this.n_units / this.pageSize);
        }
    },
    mounted() {
        this.fetchUnits();
    },
    methods: {
        backMainPanel() {
            window.location.href = '/main-panel';
        },
        addUnit() {
            window.location.href = '/agregar-nueva-unidad';
        },
        goToPage(pageNumber) {
            this.currentPage = pageNumber;
            const page = this.currentPage
            this.fetchUnits(page);
        },
        async fetchUnits(page = 1) {
            /**Fetches the in process notes using the provided filters*/
            let response
            const apiDirectory = process.env.API_DIRECTORY;

            try {
                response = await axios.post(`${apiDirectory}/get-unit?page=${page}&page_size=${this.pageSize}`,this.filters)
                const responseData = response.data.results;
                this.units = responseData
                this.n_units = response.data.count
                this.originalUnits = [...responseData]
            }
            catch (error) {
                console.error('Error fetching data from API:', error)
            }
        },
        nextPage() {
            if (this.currentPage < this.totalUnitsPages) this.currentPage++;
            const page = this.currentPage
            this.fetchUnits(page);
        },
        prevPage() {
            if (this.currentPage > 1) this.currentPage--;
            const page = this.currentPage
            this.fetchUnits(page);
        },
        applyFiltersUnit() {
            this.fetchUnits()
        },
        viewUnitDetails(unit_id) {
            Swal.fire({
                title: '¿Estás seguro?',
                text: `¿Quieres ver los detalles de la unidad con el ID ${unit_id}?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, ver detalles',
                cancelButtonText: 'No, cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$router.push({ name: 'EditUnitProfile', params: { unit_id } });
                }
            });
        },
    }
}
</script>