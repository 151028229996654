<template>
    <div class="map">
      <div id="map" style="width: 100%; height: 100%"></div>
    </div>
</template>

<script>
/* global google */
import { onMounted } from 'vue';

export default {
    setup() {
        const mapCenter = { lat: 40.7128, lng: -74.006 };
        const origin = { lat: 40.7128, lng: -74.006 }; // Change origin
        const destination = { lat: 25.651845, lng: -100.291815 }; // Change destination
        const waypoints = [
            { location: { lat: 38.655820, lng: -111.639195 } },
            { location: { lat: 25.7, lng: -100.35 } }, // Example waypoint
            { location: { lat: 25.68, lng: -100.32 } }  // Example waypoint
        ]; // Add your waypoints here

        let markerOrigin;
        let markerDestination;

        // Load Google Maps API dynamically
        onMounted(() => {
            const script = document.createElement('script');
            const apiKey = 'AIzaSyDttJadFf5cWEcGQMFmRZCrjzUFony74xI'; // Change api-key
            script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
            script.async = true;
            script.defer = true;
            script.onload = () => {
                // Once the script is loaded, initialize the map and directions service
                initializeMap();
            };
            document.head.appendChild(script);
        });

        // Function to initialize the map and directions service
        const initializeMap = () => {
            const map = new google.maps.Map(document.getElementById('map'), {
                zoom: 10,
                center: mapCenter
            });

            // Create DirectionsService object
            const directionsService = new google.maps.DirectionsService();

            // Create DirectionsRenderer object
            const directionsRenderer = new google.maps.DirectionsRenderer({
                map: map,
                polylineOptions: {
                    strokeColor: 'violet'
                },
                suppressMarkers: true // Suppress intermediate waypoints markers
            });

            // Create marker for origin
            markerOrigin = new google.maps.Marker({
                position: origin,
                map: map,
                label: {
                    text: 'A',
                    color: 'black' // Set the color of the label letters to white
                },
                icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    fillColor: 'yellow', // Set the marker color to yellow
                    fillOpacity: 1,
                    strokeColor: 'black',
                    strokeWeight: 2,
                    scale: 10 // Adjust the scale as needed
                },
                title: 'Origin' // Tooltip for the marker
            });

            // Create marker for destination
            markerDestination = new google.maps.Marker({
                position: destination,
                map: map,
                label: {
                    text: 'B',
                    color: 'black' // Set the color of the label letters to white
                },
                icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    fillColor: 'yellow', // Set the marker color to yellow
                    fillOpacity: 1,
                    strokeColor: 'black',
                    strokeWeight: 2,
                    scale: 10 // Adjust the scale as needed
                },
                title: 'Destination' // Tooltip for the marker
            });

            const request = {
                origin: origin,
                destination: destination,
                waypoints: waypoints,
                optimizeWaypoints: true, // Optimize the order of waypoints
                travelMode: 'DRIVING'
            };

            directionsService.route(request, (result, status) => {
                if (status === 'OK') {
                    // Display the route
                    directionsRenderer.setDirections(result);
                } else {
                    console.error('Error fetching route:', status);
                }
            });
        };

        return {
            mapCenter,
            markerOrigin,
            markerDestination
        };
    }
};
</script>

<style scoped>
.map {
    position: absolute;
    top: 10vh;
    width: 100%;
    height: 90vh;
}
</style>
