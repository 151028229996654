<template>
    <div>
      <!-- Dynamically render the form component based on the note type -->
      <component :is="formComponent" :assignations="assignations"></component>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import OrderDetails from './OrderDetails.vue'; // Example of a form component for a specific type
  // Import other form components
  
  export default {
    components: {
      OrderDetails,
      // Register other form components here
    },
    data() {
      return {
        assignations: "",
      };
    },
    computed: {
      formComponent() {
        return 'OrderDetails';
      },
    },
    async mounted() {
      await this.fetchAssignations();
    },
    methods: {
      async fetchAssignations(){
        const assignation_id = this.$route.params.assignation_id; // Get noteID from route params
        try {
          const apiDirectory = process.env.API_DIRECTORY;
          const response = await axios.get(`${apiDirectory}/assignation-detail/${assignation_id}`);
          this.assignations = response.data;
          console.log("assignations are",this.assignations)
        }
        catch (error){
          console.error("Failed to fetch assignations details:" ,error);
        }
      },
    }
  };
  </script>