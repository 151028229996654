<template>
  <div class="nav-bar-operations">
      <img src="@/assets/logogruas.png" alt="HomePage" class="logo-button"/>
  </div>
  <div class="side-bar">
    <!-- Removed the input field -->
    <!-- Removed the filter-results div -->
  </div>
  <div class="map">
    <Map
      :center="mapCenter"
      :zoom="10"
      map-type-id="roadmap"
      style="width: 100vw; height: 100vh"
    >
      <!-- Display blue markers for vehicle locations -->
      <Marker
        v-for="location in filteredBlueLocations"
        :key="'blue_' + location.vehicle_plate"
        :position="{ lat: parseFloat(location.lat), lng: parseFloat(location.long) }"
        :icon="require('@/assets/grua-map-icon-marker-gray.png')"
        :options="{ zIndex: 5 }" 
        @click="openInfoWindow(location)"
      />

      <!-- Display red marker at a specified location -->
      <Marker
        v-if="showRedMarker"
        :position="redMarkerPosition"
        :icon="require('@/assets/red-marker.png')"
        :options="{ zIndex: 99999 }" 
      />

      <!-- Display green markers for specific vehicle locations -->
      <Marker
        v-for="location in filteredGreenLocations"
        :key="'green_' + location.vehicle_plate"
        :position="{ lat: parseFloat(location.lat), lng: parseFloat(location.long) }"
        :icon="require('@/assets/grua-map-icon-marker.png')"
        :options="{ zIndex: 9999 }" 
        @click="openInfoWindow(location)"
      />

      <!-- InfoWindow component -->
      <InfoWindow
        :options="{ content: infoContent }"
        :position="infoPosition"
        :opened="infoWindowOpened"
        @closeclick="infoWindowOpened = false"
      />
    </Map>
  </div>
</template>

<script>
import { ref, reactive, onMounted, nextTick, computed } from 'vue'
import { Map, Marker, InfoWindow } from '@fawmi/vue-google-maps'
import axios from 'axios';

export default {
  components: {
    Map,
    Marker,
    InfoWindow
  },
  setup() {
    const locations = ref([])
    const mapCenter = ref({ lat: 25.66831199290133, lng: -100.2802354639708 })
    const infoWindowOpened = ref(false);
    const infoContent = ref('');
    const infoPosition = reactive({ lat: 0, lng: 0 });
    const redMarkerPosition = { lat: 25.689214, lng: -100.314343 }; // Red marker position DESTINATION
    const showRedMarker = ref(true); // Variable to control the red marker visibility

    // Vector of vehicle names for green markers
    const greenVehicleNames = ['264', '195', '267']; // Example vehicle names

    const fetchLocations = () => {
      const apiDirectory = process.env.API_DIRECTORY;
      axios.get(`${apiDirectory}/fetch-last-vehicle-locations`)
        .then(response => {
          locations.value = response.data;
        })
        .catch(error => {
          console.error('Error fetching locations:', error);
        });
    }

    onMounted(() => {
      fetchLocations();
      setInterval(fetchLocations, 20000);
    })

    const openInfoWindow = (location) => {
      infoWindowOpened.value = false;
      infoContent.value = `
        <div>
          <h1>${location.vehicle_name}</h1>
          <p>Placas: ${location.vehicle_plate}</p>
          <p>Velocidad Actual: ${location.vehicle_speed} km/h</p>
        </div>
      `;
      infoPosition.lat = parseFloat(location.lat);
      infoPosition.lng = parseFloat(location.long);
      nextTick(() => {
        infoWindowOpened.value = true;
      });
    };

    // Computed property for filtering blue locations
    const filteredBlueLocations = computed(() => {
      return locations.value.filter(location => !greenVehicleNames.includes(location.vehicle_name));
    });

    // Computed property for filtering green locations
    const filteredGreenLocations = computed(() => {
      return locations.value.filter(location => greenVehicleNames.includes(location.vehicle_name));
    });

    return {
      filteredBlueLocations,
      filteredGreenLocations,
      mapCenter,
      infoWindowOpened,
      infoContent,
      infoPosition,
      openInfoWindow,
      redMarkerPosition,
      showRedMarker,
    }
  }
};
</script>

<style scoped>
.nav-bar-operations {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100vw; 
    height: 10vh; 
    background-color: #FFC434;
    z-index: 1000; 
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
}

.map {
  position: absolute;
  top: 10vh;
  left: 0vw;
  width: 80vw;
  height: 90vh;
}
</style>
