<template>
    <div class="background-price">
        <div class="nav"></div>
        <div v-if="!displayMap">
            <div class="void"></div>
            <div class="warning-alert">
                <div class="reminder">
                    <h3>Estos costos podrían variar en casos especiales, tales como:</h3>
                </div>
                <div class="reminder-list">
                    <ul>
                        <li>1. Casetas</li>
                        <li>2. Maniobras especiales</li>
                        <li>3. Siniestros</li>
                    </ul>
                </div>
            </div>
            <div class="container-form-price">
                <div class="text-operation">
                  <h2>Cotizar servicio</h2>
                </div>
                <div class="form-container">
                    <!-- Client Form -->
                    <div class="form-section">
                        <h3>Datos personales</h3>
                        <img src="@/assets/client-icon.png">
                        <div class="form-group">
                            <label for="name">Nombre</label>
                            <input type="text" id="name" placeholder="Ingrese su nombre" v-model="clientName" autocomplete="off" />
                        </div>
                        <div class="form-group">
                            <label for="last_name">Apellidos</label>
                            <input type="text" id="last_name" placeholder="Ingrese sus apellidos" v-model="clientLastName" autocomplete="off" />
                        </div>
                        <div class="form-group">
                            <label for="company">Empresa o rubro</label>
                            <input type="text" id="company" placeholder="Ingrese su empresa o rubro" v-model="company" autocomplete="off" />
                        </div>
                        <div class="form-group">
                            <label for="phone">Teléfono de contacto</label>
                            <input type="text" id="phone" placeholder="Ingrese su teléfono para contactarlo" v-model="phone" autocomplete="off" />
                        </div>
                    </div>
                    <!-- Vehicle Form -->
                    <div class="form-section">
                        <h3>¿Qué tipo de vehículo desea trasladar?</h3>
                        <img src="@/assets/vehicle-icon.png">
                        <div class="form-group">
                            <label for="Type">Selecciona:</label>
                        <select v-model="vehicleType" class="form-input">
                          <option value="A">Tipo A</option>
                          <option value="B">Tipo B</option>
                          <option value="C">Tipo C</option>
                          <option value="D">Tipo D</option>
                        </select>
                    </div>
                    <div class="form-group">
                            <label for="model">Modelo</label>
                            <input type="text" id="model" placeholder="Ingrese el modelo (año)" v-model="model" autocomplete="off" />
                        </div>
                        <h3>Consideraciones especiales</h3>
                        <div class="form-group">
                            <label for="model">Maniobras</label>
                            <input type="text" id="model" placeholder="Ingrese el estimado de maniobras" v-model="maneuvers" autocomplete="off" />
                        </div>
                        <div class="form-group">
                            <label for="model">Casetas</label>
                            <input type="text" id="model" placeholder="Ingrese el estimado de casetas" v-model="highwayTolls" autocomplete="off" />
                        </div>
                        <div class="form-group">
                            <label for="model">Tiempo de espera</label>
                            <input type="text" id="model" placeholder="Ingrese el estimado de tiempo de espera (si no hay, 0)" v-model="waitingTime" autocomplete="off" />
                        </div>
                    </div>
                    <!-- Source and Destination Form -->
                    <div class="form-section">
                        <h3>Ubicación de origen y destino</h3>
                        <img src="@/assets/ubi-icon.png">
                        <div class="form-group">
                            <label for="origin_address">Origen</label>
                            <input type="text" id="origin_address" placeholder="Ingrese la ubicación de origen" v-model="originAddress" autocomplete="off" />
                        </div>
                        <div class="form-group">
                            <label for="destination_address">Destino</label>
                            <input type="text" id="destination_address" placeholder="Ingrese la ubicación de destino" v-model="destinationAddress" autocomplete="off" />
                        </div>
                        <div class="form-group">
                            <label for="notes">Notas adicionales</label>
                            <textarea id="notes" rows="5" placeholder="Ingrese notas para facilitar la ubicación de origen. Ej. En la esquina hay un OXXO. El vehículo está enfrente de la iglesia San Patricio" v-model="additionalNotes" autocomplete="off"></textarea>
                        </div>
                    </div>
                </div>
                <button @click="showMap" class="btn-save">Siguiente</button>
            </div>
        </div>
        <div v-if= "displayMap">
            <div class="map-container">
            <div class="map" id="map" style="width: 50%; height: 60vh;"></div>
                <div class="quote-text">
                    <h1>Cotización aproximada</h1>
                    <h2>Distancia de base a origen: {{ Number(distanceBase).toFixed(2) }} KM</h2>
                    <h2>Distancia de origen a destino: {{ Number(distanceKm).toFixed(2) }} KM</h2>
                    <h2>Tiempo estimado: {{ estimatedTime }}</h2>
                    <h2>{{ totalPrice.toFixed(2) }} MXN (servicio)</h2>
                    <h2>{{ maneuvers }} MXN (maniobras)</h2>
                    <h2>{{ highwayTolls }} MXN (casetas)</h2>
                    <h2>{{ waitingTime }} MXN (tiempo de espera aproximado)</h2>
                    <h2>{{ finalPrice.toFixed(2) }} MXN TOTAL</h2>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.background-price{
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    background: url('@/assets/bgprice.png');
}
.nav{
    position: absolute;
    width: 100vw;
    height: 10vh;
    top: 0;
    left: 0;
    background-image: url('@/assets/logogruas.png');
    background-color:#ffffff;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
}
.void{
    display: flex;
    margin-bottom: 10vh;
}
.text-operation{
    color: #ffffff;
    display: flex;
    justify-content: flex-start; 
    align-items: flex-start; /* Align child elements at the beginning */
    margin-left: 1vw;
}
.container-form-price{
    background-color: #FFC434;
}
.form-container {
    display: flex;
    justify-content: space-between;
    margin: 5px;
}
.form-section {
    flex-basis: calc(33% - 10px); /* Setting for margin between sections */
    margin-right: 10px; /* Margin between sections */
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1); /* Soft shadow to highlight sections */
    position: relative;
}
.form-section:last-child {
    margin-right: 0; /* So that there is no margin to the right of the last section */
}
.form-section h3{
    color: #ffffff;
}
.reminder, .reminder-list {
    margin-left: 0;
    padding-left: 20px; 
    width: calc(100% - 10px);
}
h2 {
    margin-bottom: 20px; 
}
.form-group label {
    text-align: left; /* Adjust this value to align with the content above */
    font-weight: bold; /* Makes the label text bold */
    display: inline-block; /* Makes the label block but aligns its content */
    width: 100%; /* Ensures that the label takes up all available width */
}
.form-section img{
    width: 40px;
    height: 40px;
}
.form-group {
    margin-bottom: 15px;
    width: 10vw;
    color: #ffffff;
}

label {
    display: block;
    margin-bottom: 10px;
}

input[type="text"],
input[type="tel"] {
    width: 30vw;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.btn-save {
    background-color: #2717ff; /* Submit button color */
    color: #fff; /* Button text color */
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}
.warning-alert{
    background-color: #ff0000;
    color: #ffffff;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.reminder {
    margin-top: 0;
}

.reminder-list{
    display: flex;
    align-items: center;
    justify-content: center;
}
.reminder-list ul {
    list-style: none;
    padding-left: 0; /* Remove the default padding from the list */
    text-align: left; /* Align items to the left if necessary */
}

.reminder-list ul li {
    margin: 5px 0;
}
textarea {
    width: 30vw; /* The same width as the inputs */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    resize: vertical; /* Allows the user to resize vertically, but not horizontally */
}
.map-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
}

.quote-text {
    flex: 1 0 50%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: flex-start; /* Center the text */
    padding: 20px;
    color: #ffffff;
}

@media (max-width: 576px) {
    .map-container {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start; /* Optional, to align to the beginning of the container */
        height: auto; /* Adjust the height of the map container */
        padding: 0; /* Remove any padding if necessary */
    }
    .map {
        width: 85%; /* Adjust width for mobile devices */
        height: 50vh; /* Adjust width for mobile devices */
        max-height: 300px; /* Add a maximum height if necessary */
    }
    .quote-text {
        order: -1; /* Make sure the text appears on top of the map */
        width: 85%; /* Makes the width of the text equal to that of the map to maintain consistency */
        margin: 0 auto; /* Center the text horizontally */
        padding: 10px; /* Adjust padding as necessary */
        text-align: center; /* Center the text if necessary */
    }
}

/* Media query for tablets */
@media (max-width: 992px) { 
    .form-container {
        flex-direction: column;
    }

    .form-section {
        flex-basis: 100%; /* Will occupy all available width */
        margin-right: 0;
        margin-bottom: 20px; /* Space between sections */
    }

    input[type="text"],
    input[type="tel"],
    textarea {
        width: 90%; /* More space for input */
    }
}

/* Media query for mobile */
@media (max-width: 576px) { 
    .nav, .warning-alert, .form-section, .map-container, .quote-text {
        width: 100%; /* Occupies the entire width of the screen */
    }

    .form-group {
        width: auto; /* Allows the form-group size to be automatic */
    }

    .btn-save {
        width: 100%; /* Wider button for easier interaction */
        padding: 15px 0; /* More vertical space */
    }
}

.form-input {
  width: 30vw; /* Match the width of other inputs */
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

</style>

<script>
  /* global google */
import { onMounted, ref } from 'vue';
import axios from 'axios';

export default {
  setup() {
    const displayMap = ref(false);
    const originAddress = ref(''); // Holds the user input for the origin address
    const destinationAddress = ref(''); // Holds the user input for the destination address

    const mapCenter = ref({ lat: 40.7128, lng: -74.006 });
    const vehicleType = ref('');
    const totalPrice = ref(0);
    const distanceKm = ref(0);
    const distanceBase = ref(0);
    const estimatedTime = ref();
    const clientName = ref('');
    const clientLastName = ref('');
    const company = ref('');
    const phone = ref('');
    const model = ref('');
    const additionalNotes = ref('');
    const maneuvers = ref(0);
    const highwayTolls = ref(0);
    const waitingTime = ref(0);
    const finalPrice = ref(0);

    const saveQuote = async () => {
      const quoteData = {
        ident: 'test',
        obs_time: new Date().toISOString(),
        first_name: clientName.value,
        last_name: clientLastName.value,
        company: company.value,
        phone: phone.value,
        category: vehicleType.value,
        model: model.value,
        source: {'address': originAddress.value},
        destination: {'address': destinationAddress.value},
        notes: additionalNotes.value,
        distance_km: distanceKm.value,
        estimated_time: estimatedTime.value,
        total_price: parseFloat(parseFloat(totalPrice.value).toFixed(2)),
        maneuvers: maneuvers.value,
        highway_tolls: highwayTolls.value,
        waiting_time: waitingTime.value
      };
  
      try {
        const apiDirectory = process.env.API_DIRECTORY;
        await axios.post(`${apiDirectory}/create-pricing-quote`, quoteData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        alert('Quote saved successfully!');
      } catch (error) {
        console.error('Failed to save quote:', error);
        alert('Failed to save the quote.');
      }
    };

    const saveDataAndShowMap = async () => {
      await showMap(); // Shows the map and calculates the quote
      await saveQuote(); // Saves the quote data
    };


    const fetchDistanceAndTime = async (originCoords, destinationCoords) => {
  try {
    const apiDirectory = process.env.API_DIRECTORY;
    const response = await axios.post(`${apiDirectory}/get-distance-and-time`, {
      org_lat: originCoords.lat,
      org_lng: originCoords.lng,
      dest_lat: destinationCoords.lat,
      dest_lng: destinationCoords.lng
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch distance and time:', error);
    return null;
  }
};

const calculatePrice = (type, totalDistanceKm) => {
  let basePrice = 0;
  let costPerKm = 0;
  let totalCost = 0;
  let excessKm = totalDistanceKm > 10 ? totalDistanceKm - 10 : 0;

  if (totalDistanceKm <= 10) {
    // If total distance (including base to origin) is 10 km or less, use base price.
    switch (type) {
      case 'A':
        basePrice = 800;
        break;
      case 'B':
      case 'C':
        basePrice = 1750;
        break;
      case 'D':
        basePrice = 2800;
        break;
    }
    totalCost = basePrice;
  } else {
    // For distances greater than 10 km, adjust pricing based on type and excess km.
    switch (type) {
      case 'A':
        basePrice = 800;
        costPerKm = totalDistanceKm < 100 ? 38 : 34;
        break;
      case 'B':
        basePrice = 1750;
        costPerKm = 50;
        break;
      case 'C':
        basePrice = 1750;
        costPerKm = totalDistanceKm < 100 ? 50 : totalDistanceKm < 800 ? 40 : 36;
        break;
      case 'D':
        basePrice = 2800;
        costPerKm = totalDistanceKm < 50 ? 70 : totalDistanceKm < 600 ? 60 : 50;
        break;
    }
    totalCost = basePrice + (costPerKm * excessKm);
  }

  return totalCost;
};

    const calculateFinalPrice = (totalPrice, maneuvers, highwayTolls, waitingTime) => {

      let final = 0;

      final = Number(totalPrice) + Number(maneuvers) + Number(highwayTolls) + Number(waitingTime);
      console.log(final)
      return final

    };

    // Function to fetch coordinates for a given address
    const fetchCoordinates = async (address) => {
  try {
    const apiDirectory = process.env.API_DIRECTORY;
    const response = await axios.post(`${apiDirectory}/get-address-coordinates`, { "address": address }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch coordinates:', error.response.data);
    return null;
  }
};

const showMap = async () => {
  // Fetch coordinates for both addresses
  const originCoords = await fetchCoordinates(originAddress.value);
  const destinationCoords = await fetchCoordinates(destinationAddress.value);
  console.log("Selected vehicle type:", vehicleType.value);
  const baseCoordinates = { lat: 25.668094, lng: -100.2803334 };

  if (originCoords && destinationCoords) {
    // Now fetch distance and time between the two points
    const distanceAndTime = await fetchDistanceAndTime(originCoords, destinationCoords);
    const distanceToBase = await fetchDistanceAndTime(baseCoordinates, originCoords);
    console.log(distanceToBase);
    if (distanceAndTime && distanceAndTime.distance) {
      // Convert distance from meters to kilometers for pricing calculation and display
      distanceKm.value = distanceAndTime.distance / 1000;
      distanceBase.value = distanceToBase.distance / 1000;
      const baseToOriginTime = distanceToBase.duration / 3600
      estimatedTime.value = Number(distanceAndTime.duration / 3600) + Number(baseToOriginTime);
      const hours = Math.floor(estimatedTime.value);
      const minutes = Math.round((estimatedTime.value - hours) * 60);
      estimatedTime.value = `${hours} hora${hours === 1 ? '' : 's'} ${minutes} minuto${minutes === 1 ? '' : 's'}`;
      // Calculate the total price based on vehicle type and distance
      console.log("Vehicle Type:", vehicleType.value, "Distance KM:", distanceKm.value);
      totalPrice.value = calculatePrice(vehicleType.value, (Number(distanceKm.value)+Number(distanceBase.value)));
      finalPrice.value = calculateFinalPrice(totalPrice.value, maneuvers.value, highwayTolls.value, waitingTime.value)

      // Update mapCenter and display the map
      mapCenter.value = originCoords;
      displayMap.value = true;
      loadMap(originCoords, destinationCoords, baseCoordinates);
    } else {
      alert('Unable to fetch distance and time for the provided addresses.');
    }
  } else {
    alert('Unable to fetch coordinates for the provided addresses.');
  }
};

    // Adjust loadMap to accept coordinates
    const loadMap = (originCoords, destinationCoords, baseCoordinates) => {
      const script = document.createElement('script');
      const apiKey = 'AIzaSyDttJadFf5cWEcGQMFmRZCrjzUFony74xI'; // Use your actual API key
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        initializeMap(originCoords, destinationCoords, baseCoordinates);
      };
      document.head.appendChild(script);
    };

    // Adjust initializeMap to use provided coordinates
    const initializeMap = (originCoords, destinationCoords, baseCoordinates) => {
      const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new google.maps.DirectionsRenderer({
        polylineOptions: {
          strokeColor: 'red',
          strokeWeight: 4,
        },
      });
      const map = new google.maps.Map(document.getElementById('map'), {
        zoom: 10,
        center: mapCenter.value,
      });
      directionsRenderer.setMap(map);

      const request = {
        origin: baseCoordinates,
        destination: destinationCoords,
        waypoints: [{location:originCoords},],
        travelMode: 'DRIVING',
      };

      directionsService.route(request, (result, status) => {
        if (status === 'OK') {
          directionsRenderer.setDirections(result);
        } else {
          console.error('Error fetching route:', status);
        }
      });
    };


    onMounted(() => {
      // If the map should be displayed immediately, call loadMap here
      // Otherwise, it will be called when the user triggers showMap
    });

    return {
      displayMap,
      showMap: saveDataAndShowMap,
      originAddress,
      destinationAddress,
      mapCenter,
      distanceKm,
      distanceBase,
      totalPrice,
      vehicleType,
      estimatedTime,
      clientName,
      clientLastName,
      company,
      phone,
      model,
      additionalNotes,
      maneuvers,
      highwayTolls,
      waitingTime,
      finalPrice
    };
  },
};
</script>