<template>
  <div class="background-image">
    <div class="nav-bar-operations">
      <img src="@/assets/logogruas.png" class="logo-button">
    </div>
    <div class="login-container">
      <div class="login-form">
        <div class="icon-truck"></div>
        <h1>Iniciar sesión</h1>
        <form @submit.prevent="submitLogin">
          <div class="">
            <input type="text" class="form-control" v-model="username" placeholder="Usuario" required>
          </div>
          <div class="">
            <input :type="showPassword ? 'text' : 'password'" class="form-control" v-model="password" placeholder="Contraseña" required>
          </div>
          <div class="input-group-append">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="togglePasswordCheckbox" @change="togglePasswordVisibility">
          </div>
          <div class="form-check1">
            <label class="form-check-label" for="togglePasswordCheckbox">Mostrar contraseña</label>
          </div>
        </div>
            <button @click="SaveSession()" type="submit" class="btn btn-primary">
              <div v-if="loading" class="spinner-border spinner-border-sm"></div>
              <span v-if="loading" class="px-1">Entrando</span>
              <span v-else >Entrar</span>
            </button>
        </form>
        <div class="alert alert-danger" role="alert" v-if="error">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.background-image{
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    background: url('@/assets/bgimagen.jpg') no-repeat center center;
    background-size: cover;
    z-index: -1;
}
.nav-bar-operations {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100vw; 
    height: 10vh; 
    background-color: #FFC434;
    z-index: 1000; 
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
}
.login-container {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login-form {
    position: relative;
    padding: 70px;
    width: 25vw; 
    background: #ffffffdd;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    text-align: center;
    z-index: 2;
}
.icon-truck {
    position: absolute;
    top: -10%; 
    left: 50%;
    transform: translateX(-50%);
    width: 100px; 
    height: 100px; 
    background-color: #FFC434; 
    border-radius: 50px; 
    background-image: url('@/assets/icon-truck.png');
    background-size: 60%; 
    background-repeat: no-repeat;
    background-position: center;
}
.login-form input {
    margin-bottom: 10px;
    width: calc(100% - 20px); 
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.login-form button {
    margin-top: 30px; 
    width: calc(100% - 100px); 
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: rgb(255, 0, 0);
    color: white;
    cursor: pointer;
    transition: background-color 0.5s;
}
.login-form button:hover{
    background-color: #FFC434;
}
.input-group-append {
    display: flex;
    align-items: center;
}
.form-check {
    margin-right: 0.25rem; 
}
.form-check1 {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.form-check-input {
    width: 20px;
}
@media (max-width: 1250px) {
  .login-form {
    width: 90vw; 
    padding: 20px; 
  }
  .icon-truck {
    top: -80px; 
    width: 80px; 
    height: 80px; 
    background-size: 50%; 
  }
}

@media screen and (max-height: 586px) {
  .nav-bar-operations {
    height: 10vh;
  }
  .login-container {
    height: auto;
    min-height: 110vh; 
    padding-top: 10vh; 
  }
  .login-form {
    padding: 40px;
  }
  .icon-truck {
    top: -50px; 
    width: 80px;
    height: 80px;
    background-size: 50%;
  }

  .login-form input,
  .login-form button {
    width: 100%; 
  }

  .login-form button {
    margin-top: 20px;
  }

}
</style>


<script>
    import axios from 'axios';

    export default {
  data() {
    return {
      username: '',
      password: '',
      showPassword: false,
      error: false,  // Indicates if there is an error
      errorMessage: '', 
      loading: false
    };
  },
  methods: {
    submitLogin() {
      const loginData = {
        username: this.username,
        password: this.password
      };
      const apiDirectory = process.env.API_DIRECTORY;
      axios.post(`${apiDirectory}/login/`, loginData)
        .then(response => {
          localStorage.setItem('authToken', response.data.token);
          this.$store.commit('setToken', response.data.token); 
          if (response.data.is_operator) {
            this.$router.push({ name: 'OperatorInterface' }); 
          } else {
            this.$router.push({ name: 'MainPanel' }); 
          }
          this.error = false; // Reset error state
        })
        .catch(error => {
          console.error(error);
          this.error = true; // Set error state
          this.errorMessage = 'Credenciales incorrectas.'; // Display appropriate error message
        });
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    SaveSession(){
      this.loading=!false
      setTimeout(()=>{
        this.loading=!true
      },2000)
    }
  }
};

</script>
